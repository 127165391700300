import { Section, Title } from '@sweb-front/components';
import { NextButtonWrapper } from '@sweb-front/styles';
import styled from 'styled-components';

export const SectionWrapper = styled(Section)`
  position: relative;
  padding: 0;
  img {
    self-align: center;
  }
`;
export const ImgWrapper = styled.div`
  display: inline-flex;
  margin: 2rem 0 0.8rem 0;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
  & base-icon {
    width: 4rem;
    height: 4rem;
    --icon-width: 4rem;
    --icon-height: 4rem;
    --color-icon: var(--neutrals-700);
  }
`;

export const SVGIconWrapper = styled.div`
  z-index: 10;
  display: inline-flex;
  margin: 0;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: -6.8rem;
  margin-left: 10.4rem;
  width: 6rem;
  height: 6rem;
  & base-icon {
    width: 6rem;
    height: 6rem;
    --icon-width: 6rem;
    --icon-height: 6rem;
    --color-icon: var(--main-green-500);
  }
`;

export const ContentWrapper = styled.div`
  margin: 1.6rem 0;
  text-align: center;
`;

export const TitleCmp = styled(Title)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  color: var(--neutrals-700);
`;

export const SeparatorWrapper = styled.div`
  margin: 0 0 1.6rem 0;
`;

export const Paragraph = styled.p`
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  text-align: center;

  & strong {
    font-family: 'Open Sans Bold';
    margin-bottom: 2.4rem;
  }
`;

export const FirstParagraph = styled(Paragraph)`
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;
  padding: 0;
`;

export const ReceptionParagraph = styled(Paragraph)`
  margin-top: 3.2rem;
`;

export const PrintAndDownloadParagraph = styled(Paragraph)`
  margin-top: 0.8rem;
`;

export const PrintAndDownloadParagraphBold = styled.a`
  font-family: 'Open Sans Bold';
`;

export const CustomizedValidateButtonWrapper = styled(NextButtonWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  & base-button {
    flex-grow: 1;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
    --color-button: var(--neutrals-0);
    background: var(--main-green-500);
    border: 1px solid var(--main-green-500);
    max-width: 32rem;
    height: 5.6rem;
    padding: 1.25rem;
  }
  & base-icon {
    width: 0;
  }
`;

export const DocumentInPersonalAccountParagraph = styled(
  PrintAndDownloadParagraph
)`
  font-family: 'Open Sans';
  margin: 3.2rem 0.8rem 0.8rem 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: var(-neutrals-700);
`;

export const DocumentInPersonalAccountParagraphExplanation = styled(
  DocumentInPersonalAccountParagraph
)`
  font-family: 'Open Sans';
  margin-top: 1.6rem;

  &:last-of-type {
    margin-bottom: 3.2rem;
  }
`;

export const BackButtonWrapper = styled(CustomizedValidateButtonWrapper)`
  & base-button {
    padding: 0.8rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    height: 4.2rem;
    --color-button: var(--main-green-500);
    background: var(--neutrals-0);
    border: 1px solid var(--main-green-500);
  }
`;
