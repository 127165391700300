import { HelperMessageWrapper } from '@sweb-front/styles';
import styled from 'styled-components';

export const CustomedBubbleInfoWrapper = styled(HelperMessageWrapper)`
  display: flex;
  justify-content: left;
  vertical-align: middle;
  position: relative;
  border: 1px solid #1068d0;
  background: #e5f5fd;
  color: #4b4f54;

  & .bubbleinfo-description {
    flex: 1;
    align-self: center;
  }
`;

export const ImgWrapper = styled.div`
  padding: 0;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2rem;
    --icon-height: 2rem;
    --color-icon: #0a6bbf;
  }
`;
