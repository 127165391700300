import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState as IParameterState } from './parametersTypes';

const getInitialState = () => {
  return { state: {} } as { state: IParameterState };
};

export const parametersSlice = createSlice({
  name: 'parametersSlice',
  initialState: getInitialState(),
  reducers: {
    clear(parametersState) {
      parametersState.state = getInitialState().state;
    },
    fetchWay(parametersState, data: PayloadAction<IParameterState>) {
      parametersState.state = data.payload;
    },
  },
});

export const selectDisplays = (parametersState: {
  parameters: {
    state: IParameterState;
  };
}) => parametersState.parameters.state.displays;

export type { IParameterState };
export const { clear, fetchWay } = parametersSlice.actions;
export default parametersSlice.reducer;
