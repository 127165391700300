import { Section, Title, Loader } from '@sweb-front/components';
import { DESKTOP } from '@sweb-front/utils';

import styled from 'styled-components';

interface BadgeProps {
  isRecommended?: boolean;
}

export const SectionWrapper = styled(Section)`
  padding-top: 1.6rem;
`;

export const TitleWrapper = styled(Title)`
  color: var(--neutrals-700);
  margin: 0 0 1.6rem 1.6rem;
  font-family: 'Ubuntu Bold';
  font-size: 2.4rem;
  line-height: 3.2rem;
  @media screen and ${DESKTOP} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const InsuranceOfferCardWrapper = styled.div`
  border-radius: 0.8rem 0.8rem;
  background: var(--main-green-50);
  padding: 1.6rem;
  margin: 0 0 0.8rem 0;
`;

export const TitleBoldWrapper = styled(TitleWrapper)`
  font-family: 'Open Sans Bold';
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
  color: #292c2e;
`;

export const PriceBadge = styled.span<{isRecommended?: boolean}>`
  border-radius: 1rem;
  background-color: ${props => props.isRecommended ? '#e5f5fd' : 'var(--main-green-50)'};
  margin: 0.5rem 0;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  font-size: 1.4rem;
  font-family: 'Open Sans Bold';
`;

export const RowElementWrapper = styled.div`
  display: flex;
  margin-top: 2.4rem;
`;

export const OptionDocumentWrapper = styled.div`
  display: flex;
  margin-top: 1.2rem;

  & base-icon {
    margin-left: 0.4rem;
    margin-top: 0.4rem;
    --color-icon: var(--main-green-500);
    width: 1.7rem;
    height: 1.7rem;
  }
`;

export const BaseCardWrapper = styled.div`
  & base-card {
    margin-top: 0.8rem;
  }
`;

export const Fill = styled.div`
  flex-grow: 1;
`;

export const RowItem = styled.div`
  font-family: 'Open Sans';
  font-size: 1.4rem;
  color: var(--neutral-500);
`;

export const RowItemLabel = styled(RowItem)`
  width: 13rem;
`;

export const LongRowItemLabel = styled(RowItemLabel)`
  margin-top: -1rem;
`;

export const SelectButtonWrapper = styled.div`
  margin-top: 2.4rem;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & base-button {
    max-width: 30.8rem;
    padding: 1.4rem 2.6rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.6rem;
  padding: 0 1.6rem;
  & button-link {
    --color-button: var(--main-green-500);
    font-family: 'Open Sans Bold';
    font-size: 1.6rem;
  }
`;

export const WarrantiesWrapper = styled.div`
  margin-top: 4.4rem;
  padding: 0 1.6rem;
  font-family: 'Open Sans';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--neutrals-500);
  & span {
    display: block;
  }
  & p {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }
  & p:first-of-type {
    margin-top: 0;
  }
`;

export const StyledLoader = styled(Loader)`
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const FileDownloadWrapper = styled.a<{
  isLoading: boolean;
  name?: string;
}>`
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  color: var(--main-green-500);
  text-decoration: underline;
  font-size: 1.4rem;
  display: inherit;
`;
