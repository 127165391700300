import {
  ButtonLink,
  DateInput,
  DesktopSelect,
  IListItem,
  ModalSelect,
} from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonActionWrapper,
  StyledBackButtonWrapper,
  StyledValidateButtonWrapper,
} from '@vat/utils';

import { DateInputDS } from '@sweb-front/components';
import useProfessionalForm, { getListByDevice } from './useProfessionalForm';
import {
  CustomSectorListWrapper,
  InputWrapper,
  SectorListWrapper,
} from './styles';

const ProfessionalForm = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const formRef = useRef<HTMLFormElement>(null);
  const contractDtRef = useRef<DateInputDS>(null);
  const {
    isFormValid,
    isActionLoading,
    values,
    invalidityFieldsStates,
    dtPattern,
    activities,
    sectors,
    contractTypes,
    hideContractType,
    getContractDtErrorMessage,
    getInvalidityState,
    onActivitySectorChange,
    onActivityChange,
    onContractTypeChange,
    onEntryDtChange,
    onSubmit,
    goBackToStep,
    onFieldChange,
    isDesktop,
  } = useProfessionalForm(opportunity, formRef, contractDtRef);

  useEffect(() => {
    document.querySelector('#rippleContainer')?.setAttribute('style', '');
  }, []);

  return (
    <form ref={formRef}>
      {isDesktop ? (
        <CustomSectorListWrapper
          id="activitySector"
          aria-invalid={
            getInvalidityState(
              'activitySector',
              values.activitySector,
              invalidityFieldsStates,
              dtPattern
            ) as boolean
          }
        >
          <DesktopSelect
            id="input-activitySector"
            options={getListByDevice(isDesktop, sectors) as any}
            label={t('professionalSituation.form.fields.activitySector')}
            placeholder={t(
              'professionalSituation.form.placeholder.activitySector'
            )}
            errMsg={t('professionalSituation.form.errors.activitySector')}
            isError={
              !!getInvalidityState(
                'activitySector',
                values.activitySector,
                invalidityFieldsStates,
                dtPattern
              ) as boolean
            }
            defaultValue={values.activitySector}
            onChange={onActivitySectorChange}
          />
        </CustomSectorListWrapper>
      ) : (
        <SectorListWrapper
          id="activitySector"
          aria-invalid={
            getInvalidityState(
              'activitySector',
              values.activitySector,
              invalidityFieldsStates,
              dtPattern
            ) as boolean
          }
        >
          <ModalSelect
            id="input-activitySector"
            key="prof-input-activitySector"
            label={t('professionalSituation.form.fields.activitySector')}
            value={values.activitySector}
            errorMessage={t('professionalSituation.form.errors.activitySector')}
            list={getListByDevice(isDesktop, sectors) as IListItem[]}
            onChange={onFieldChange}
            invalid={invalidityFieldsStates.activitySector}
            placeholder={t(
              'professionalSituation.form.placeholder.activitySector'
            )}
            selectionTitle={t(
              'professionalSituation.form.modal.activitySector.title'
            )}
            showErrorMessage
            required
          />
        </SectorListWrapper>
      )}
      {isDesktop ? (
        <CustomSectorListWrapper
          id="activity"
          aria-invalid={
            getInvalidityState(
              'activity',
              values.activity,
              invalidityFieldsStates,
              dtPattern
            ) as boolean
          }
        >
          <DesktopSelect
            id="input-activity"
            options={getListByDevice(isDesktop, activities) as any}
            label={t('professionalSituation.form.fields.activity')}
            placeholder={t('professionalSituation.form.placeholder.activity')}
            errMsg={t('professionalSituation.form.errors.activity')}
            isError={
              !!getInvalidityState(
                'activity',
                values.activity,
                invalidityFieldsStates,
                dtPattern
              ) as boolean
            }
            defaultValue={values.activity}
            onChange={onActivityChange}
          />
        </CustomSectorListWrapper>
      ) : (
        <SectorListWrapper
          id="activity"
          aria-invalid={
            getInvalidityState(
              'activity',
              values.activity,
              invalidityFieldsStates,
              dtPattern
            ) as boolean
          }
        >
          <ModalSelect
            id="input-activity"
            key="prof-input-activity"
            label={t('professionalSituation.form.fields.activity')}
            value={values.activity}
            errorMessage={t('professionalSituation.form.errors.activity')}
            list={getListByDevice(isDesktop, activities) as IListItem[]}
            onChange={onFieldChange}
            invalid={invalidityFieldsStates.activity}
            placeholder={t('professionalSituation.form.placeholder.activity')}
            selectionTitle={t(
              'professionalSituation.form.modal.activity.title'
            )}
            showErrorMessage
            required
          />
        </SectorListWrapper>
      )}
      <InputWrapper
        id="entryDt"
        aria-invalid={
          getInvalidityState(
            'entryDt',
            values.entryDt,
            invalidityFieldsStates,
            dtPattern
          ) || false
        }
      >
        <DateInput
          ref={contractDtRef}
          no-day
          id="input-entryDt"
          label={t('professionalSituation.form.fields.entryDt')}
          value={values.entryDt}
          onLetterChanged={onEntryDtChange}
          onInputChange={onEntryDtChange}
          error-message={t(
            'professionalSituation.form.errors.entryDt.required'
          )}
          invalid={
            getInvalidityState(
              'entryDt',
              values.entryDt,
              invalidityFieldsStates,
              dtPattern
            ) || null
          }
          customValidityPattern={JSON.stringify([
            {
              regexp: '.+',
              errorMessage: t(getContractDtErrorMessage(values.entryDt ?? '')),
            },
            {
              regexp: dtPattern,
              errorMessage: t(getContractDtErrorMessage(values.entryDt ?? '')),
            },
          ])}
        />
      </InputWrapper>
      {isDesktop
        ? !hideContractType && (
            <CustomSectorListWrapper
              id="contractType"
              aria-invalid={
                getInvalidityState(
                  'contractType',
                  values.contractType,
                  invalidityFieldsStates,
                  dtPattern
                ) as boolean
              }
            >
              <DesktopSelect
                id="input-contractType"
                options={getListByDevice(isDesktop, contractTypes) as any}
                label={t('professionalSituation.form.fields.contractType')}
                placeholder={t(
                  'professionalSituation.form.placeholder.contractType'
                )}
                isError={
                  !!getInvalidityState(
                    'contractType',
                    values.contractType,
                    invalidityFieldsStates,
                    dtPattern
                  ) as boolean
                }
                errMsg={t('professionalSituation.form.errors.contractType')}
                defaultValue={values.contractType}
                onChange={onContractTypeChange}
              />
            </CustomSectorListWrapper>
          )
        : !hideContractType && (
            <CustomSectorListWrapper
              id="contractType"
              aria-invalid={
                getInvalidityState(
                  'contractType',
                  values.contractType,
                  invalidityFieldsStates,
                  dtPattern
                ) as boolean
              }
            >
              <ModalSelect
                id="input-contractType"
                key="prof-input-contracType"
                label={t('professionalSituation.form.fields.contractType')}
                value={values.contractType}
                errorMessage={t(
                  'professionalSituation.form.errors.contractType'
                )}
                list={getListByDevice(isDesktop, contractTypes) as IListItem[]}
                onChange={onFieldChange}
                invalid={invalidityFieldsStates.contractType}
                placeholder={t(
                  'professionalSituation.form.placeholder.contractType'
                )}
                selectionTitle={t(
                  'professionalSituation.form.modal.contractType.title'
                )}
                showErrorMessage
                required
              />
            </CustomSectorListWrapper>
          )}

      <ButtonActionWrapper>
        <StyledValidateButtonWrapper
          onClick={onSubmit}
          $isDisabled={!isFormValid}
          id="profsit-validate-button"
          className={!isFormValid ? 'isDisabled' : ''}
        >
          {isActionLoading ? (
            <StyledButtonLoader
              isLoading
              isTextInline
              isHideBackground={false}
            />
          ) : (
            t('common.validate')
          )}
        </StyledValidateButtonWrapper>
        <StyledBackButtonWrapper>
          <ButtonWrapper className="back-button-wrapper">
            <ButtonLink
              label={t('common.back')}
              onClick={goBackToStep}
              id="profsit-back-button"
            />
          </ButtonWrapper>
        </StyledBackButtonWrapper>
      </ButtonActionWrapper>
    </form>
  );
};

export default ProfessionalForm;
