import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

import { CheckIcon } from '../Icons';

export const Steps = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  z-index: 0;
`;

export const Wrapper = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  position: relative;
  @media only screen and ${TAB} {
    width: 3.2rem;
  }
`;

export const Label = styled.div`
  display: inline-block;
  max-width: 20rem;
  min-width: 6rem;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 1.2rem;
  margin-left: 1rem;

  @media only screen and ${TAB} {
    margin-top: 0.4rem;
    margin-left: 1.4rem;
  }
`;

export const FirstLabel = styled(Label)`
  display: inline-block;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 1.2rem;
  margin-left: 3.6rem;

  @media only screen and ${TAB} {
    margin-top: 0.4rem;
    margin-left: 1.6rem;
  }
`;

export const LastLabel = styled(Label)`
  padding-right: 4rem;
  margin-left: 1rem;
  @media only screen and ${TAB} {
    margin-top: 0.4rem;
    margin-left: 3rem;
  }
`;

export const FuturLabel = styled(Label)`
  margin-top: 1.2rem;
  margin-left: 1rem;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 1.2rem;
  height: 1.2rem;
  stroke: white;
`;

const Step = styled.li`
  position: relative;
  text-align: center;
  line-height: 1.4rem;
  flex-grow: 1;
  color: ${({ theme }) => theme.vendor.colors.primary};

  &::after {
    content: '';
    width: 102%;
    position: absolute;
    top: 1rem;
    height: 0.2rem;
    left: 2rem;
    z-index: -1;
    background-color: ${({ theme }) => theme.vendor.colors.primary};
  }

  @media only screen and ${TAB} {
    &::after {
      top: 1.5rem;
    }
  }

  &:last-child {
    flex-grow: 0;

    &::after {
      content: none;
    }
  }
`;

export const PastStep = styled(Step)``;

export const CurrentStep = styled(Step)`
  &::after {
    background-color: var(--neutrals-100);
  }
`;

export const FuturStep = styled(Step)`
  color: var(--neutrals-100);

  ${Wrapper} {
    padding: 0.75rem;
  }

  &::after {
    background-color: var(--neutrals-100);
  }
`;

export const FullCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.vendor.colors.primary};
  color: white;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  border: 0.2rem solid ${({ theme }) => theme.vendor.colors.primary};

  @media only screen and ${TAB} {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1.6rem;
  }
`;

export const Circle = styled(FullCircle)`
  border-color: ${({ theme }) => theme.variables.colors.chartColors.primary};
  background-color: white;
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: 1.1rem;
  font-weight: bold;
  @media only screen and ${TAB} {
    font-size: 1.6rem;
  }
`;

export const SmallCircle = styled(FullCircle)`
  border: none;
  background-color: var(--neutrals-100);
  width: 100%;
  height: 100%;
  margin-left: 0.8rem;
  @media only screen and ${TAB} {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
