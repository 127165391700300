import { Subscription } from 'rxjs';

interface PfFraudnet extends Element {
  subscribe: (callback?: (value: any) => void) => Subscription;
  unsubscribe: () => void;
}
const useFraudnet = () => {
  const fraudnet: PfFraudnet | null =
    document.body.querySelector('pf-fraudnet');

  const subscribe = (callback?: (value: any) => void) => {
    if (fraudnet === null) {
      (document.body.querySelector('pf-fraudnet') as any).subscribe(callback);
    } else {
      fraudnet.subscribe(callback);
    }
  };
  // eslint-disable-next-line consistent-return
  const unsubscribe = () => {
    if (fraudnet !== null) return fraudnet.unsubscribe();
  };

  return [subscribe, unsubscribe];
};

export default useFraudnet;
