import {
  ERRORPAGE,
  OBCONNEXIONERROR,
  SITUATIONFAMILIALE,
} from '@vat/configuration';
import { useCallback, useState } from 'react';
import {
  IOpportunityState,
  fetchWay,
  setSteps,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
} from '@sweb-front/store';
import { useCreditParameters } from '@sweb-front/hooks';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { useCloseOpportunity } from '@vat/utils';

const useObConnexionError = (opportunity: IOpportunityState) => {
  const [isActionLoading, setIsActionLoading] = useState(false);
  const { recalculateCreditParameters } = useCreditParameters();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateIsActionLoading = (state: boolean) => {
    setIsActionLoading(state);
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (isActionLoading) {
        return;
      }
      const { overdraftAmt } =
        opportunity?.offers?.[0]?.loans?.[0]?.financialDetails;
      const { distributorNb } = opportunity?.distributor;
      const { productTypeCd } =
        opportunity?.offers?.[0]?.loans?.[0]?.financialProduct;
      updateIsActionLoading(true);
      try {
        const { appSteps, result } = await recalculateCreditParameters(
          distributorNb,
          overdraftAmt.toString(),
          productTypeCd,
          'O',
          'KO'
        );
        updateIsActionLoading(false);
        if (appSteps && appSteps.length > 0) {
          dispatch(fetchWay(result));
          // remove isSubStep if it is the first page and replace the situation step by informations on the stepLine
          const firstStep = appSteps.find((st) => st.step === 1);
          if (firstStep && firstStep.paths.includes(SITUATIONFAMILIALE)) {
            firstStep.label = t('basicInformations.stepTitle');
          }
          delete firstStep.isSubStep;
          dispatch(setSteps(appSteps));
          dispatch(
            updateSteps({
              externalAppName: 'openBanking',
              isSeDone: true,
            })
          );
          dispatch(
            updateParcoursNavigation({
              name: OBCONNEXIONERROR,
              actionPageDone: true,
            })
          );
          navigate('/');
        } else {
          dispatch(
            updateSteps({
              externalAppName: 'openBanking',
              isErrorHappened: true,
              returnUrl: '',
            })
          );
          dispatch(
            updateParcoursNavigation({
              name: OBCONNEXIONERROR,
              actionPageDone: false,
              params: {
                hasError: true,
              },
            })
          );
          updateAbortErrorMessage(
            'OpenBanking::fetchParameters en mode automatique, les étapes du parcours sont vides'
          );
          navigate(ERRORPAGE);
        }
      } catch {
        dispatch(
          updateParcoursNavigation({
            name: OBCONNEXIONERROR,
            actionPageDone: false,
            params: {
              hasError: true,
            },
          })
        );
        dispatch(
          updateSteps({
            externalAppName: 'openBanking',
            isErrorHappened: true,
            returnUrl: '',
          })
        );
        updateIsActionLoading(false);
        updateAbortErrorMessage(
          'OpenBanking::fetchParameters recalcul parcours rencontre une erreur'
        );
        navigate(ERRORPAGE);
      }
    },
    [opportunity, isActionLoading]
  );

  return {
    onSubmit,
    isActionLoading,
  };
};
export default useObConnexionError;
