import {
  AutoCompleteField,
  BaseInput,
  ButtonLink,
  ComboInput,
  DateInput,
  DesktopSelect,
  GenderInput,
  Modal,
  ModalSelect,
} from '@sweb-front/components';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';
import { INFORMATIONPAGE } from '@vat/configuration';
import {
  ButtonActionWrapper,
  ModalWrapper,
  StyledBackButtonWrapper,
  StyledValidateButtonWrapper,
  capitalize,
  phoneFormatter,
} from '@vat/utils';

import { useAppSelector } from '@sweb-front/store';
import { CookiesCheckbox } from '@vat/components';
import { getCityErrorMessage } from '../utils';
import {
  BoldTitleWrapper,
  ButtonModalWrapper,
  ContentWrapper,
  DescriptionWrapper,
  FirstInputWrapper,
  InputWrapper,
  LastInputWrapper,
  MNAIInputWrapper,
  SVGIconWrapper,
  TitleWrapper,
} from './Styles';
import useInformationForm from './useInformationForm';
import { SectorListWrapper } from '../IncomeExpensesForm/styles';
import { Trans } from 'react-i18next';
import { HelperMessageWrapper } from '@sweb-front/styles';
import { ModalLeave } from '@vat/layouts';
import CustomerInformationnCard from 'src/libs/components/CustomomerInformationCard/CustomerInformationCard';
export enum KYCEDITABLEFIELDS {
  BirthCountry = 'birthCountryIsoCd',
  BirthDepartment = 'birthDepartment',
  BirthCityInseeCd = 'birthCityInseeCd',
  BirthCity = 'birthCity',
  NationalityIsoCd = 'nationalityIsoCd',
  ActivitySector = 'activitySector',
}

export interface IFormData {
  title?: string; // civilité
  birthName?: string; // Nom de naissance
}

export interface IInformationProps {
  fields: string[];
}

const textPattern = "[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+";
const replacement = [{ regexp: textPattern, replacement: '' }];

const InformationForm = ({ fields }: IInformationProps) => {
  const referentiels = useAppSelector((state) => state.referentiel.state);
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);

  const {
    showAutoCompletedField,
    formRef,
    person,
    dateOfBirth,
    birthCountryValue,
    isBirthCountryFR,
    birthDepartmentValue,
    birthCityValue,
    cityRef,
    freeCityRef,
    nationalityIsoCd,
    isFormValid,
    showModal,
    showModalLeave,
    birthNameErrorMsg,
    birthCountryCustomPattern,
    birthDepartmentCustomPattern,
    customValidityPatternBirthCityFr,
    customValidityPatternBirthCityNotFr,
    nationalityCustomValidityPattern,
    isActionLoading,
    dopcOptin,
    invalidityFieldsStates,
    isDesktop,
    sectors,
    mobileSectors,
    onChangeSector,
    onActivitySectorChange,
    autocompleteBirthCity,
    defaultAutocompleteValue,
    t,
    openPopUpRetour,
    openPopUpEdit,
    getInvalidityState,
    onSubmit,
    onChangeCivility,
    onChangeBirthName,
    onChangeBirthDt,
    getBirthDtErrorMessage,
    onSelectBirthCountry,
    onInputChangeBirthCountry,
    onLetterChangedBirthCountry,
    onChangeBirhtDepartment,
    onChangeBirhtCity,
    onChangeNationality,
    onShowModalFn,
    returnToWebMerchant,
    onShowModalLeaveFn,
    onUpdateOptin,
    onUpdateDopc,
    onChangeAutoCompletedField,
    onSuggestionAutoCompletedFieldClick,
    onBlurAutocomplete,
    isBirthNamaFocused,
    setIsBirthNameFocused,
  } = useInformationForm({
    opportunity,
    referentiels,
    parameters,
    textPattern,
    fields,
  });

  return (
    <>
      <form onSubmit={onSubmit} id="information-form" ref={formRef}>
        <>
          {fields.includes(INFORMATIONPAGE.KYCBLOC1) && (
            <FirstInputWrapper
              id="civility"
              aria-invalid={
                getInvalidityState(
                  'title',
                  person?.personalInformation?.title
                ) as boolean
              }
            >
              <GenderInput
                label={t('basicInformations.civility.label')}
                value={person?.personalInformation?.title}
                onSelectedElementChange={onChangeCivility}
                invalid={
                  getInvalidityState(
                    'title',
                    person?.personalInformation?.title
                  ) || null
                }
                error-message={t('basicInformations.errors.civility')}
                hasFocus
                id="input-civility"
              >
                <selected-button value="MME" id="gender-mme">
                  {t('basicInformations.civility.OptionFemale')}
                </selected-button>
                <selected-button value="MR" id="gender-mr">
                  {t('basicInformations.civility.OptionMale')}
                </selected-button>
              </GenderInput>
            </FirstInputWrapper>
          )}

          {fields.includes(INFORMATIONPAGE.KYCNOMNAI) && (
            <MNAIInputWrapper
              id="birthName"
              aria-invalid={
                getInvalidityState(
                  'birthName',
                  person?.personalInformation?.birthName
                ) as boolean
              }
            >
              <BaseInput
                id="input-birthName"
                label={t('basicInformations.birthName')}
                placeholder={t('basicInformations.placeHolder.birthName')}
                value={person?.personalInformation?.birthName}
                onLetterChanged={onChangeBirthName}
                onInputChange={onChangeBirthName}
                error-message={t('basicInformations.errors.birthName')}
                replacement={JSON.stringify(replacement)}
                maxlength={30}
                invalid={getInvalidityState(
                  'birthName',
                  person?.personalInformation?.birthName
                )}
                customValidityPattern={birthNameErrorMsg}
                onFocus={() => setIsBirthNameFocused(true)}
                onBlur={() => setIsBirthNameFocused(false)}
              />
              {isBirthNamaFocused && (
                <HelperMessageWrapper>
                  <Trans
                    i18nKey="basicInformations.helpMessages.birthName"
                    components={{
                      birthname: (
                        <span>{t('basicInformations.birthDate')}</span>
                      ),
                    }}
                  />
                </HelperMessageWrapper>
              )}
            </MNAIInputWrapper>
          )}

          {fields.includes(INFORMATIONPAGE.KYCDTNAI) && (
            <InputWrapper
              id="birthDt"
              aria-invalid={
                getInvalidityState(
                  'birthDt',
                  person?.personalInformation?.birthDt
                ) || false
              }
            >
              <DateInput
                id="input-birthDt"
                label={t('basicInformations.birthDate')}
                value={dateOfBirth}
                onLetterChanged={onChangeBirthDt}
                onInputChange={onChangeBirthDt}
                error-message={t('basicInformations.errors.birthDt.required')}
                invalid={
                  getInvalidityState(
                    'birthDt',
                    person?.personalInformation?.birthDt
                  ) || null
                }
                customValidityPattern={getBirthDtErrorMessage()}
              />
            </InputWrapper>
          )}

          {showAutoCompletedField ? (
            <InputWrapper
              id="autoCompleteBirthCity"
              aria-invalid={
                getInvalidityState(
                  'autocompleteBirthCity',
                  autocompleteBirthCity
                ) || false
              }
            >
              <AutoCompleteField
                onChange={onChangeAutoCompletedField}
                label={t('basicInformations.birthCity')}
                id="input-autoCompleteBirthCity"
                value={autocompleteBirthCity ?? defaultAutocompleteValue}
                url="/localities/v1/cities"
                required
                suggestionLabel={t('common.countryNotFound')}
                placeholder={t(
                  'basicInformations.placeHolder.autocompleteBirthCity'
                )}
                onSuggestionClick={onSuggestionAutoCompletedFieldClick}
                emptyErrorMessage={t(
                  'basicInformations.errors.autocompleteBirthCity.emptyValue'
                )}
                invalidErrorMessage={t(
                  'basicInformations.errors.autocompleteBirthCity.invalidValue'
                )}
                onBlur={onBlurAutocomplete}
                valid={
                  autocompleteBirthCity === undefined
                    ? undefined
                    : !getInvalidityState(
                        'autocompleteBirthCity',
                        autocompleteBirthCity
                      )
                }
              />
            </InputWrapper>
          ) : (
            <>
              {fields.includes(INFORMATIONPAGE.KYCPAYSNAI) && (
                <InputWrapper
                  id={KYCEDITABLEFIELDS.BirthCountry}
                  aria-invalid={
                    getInvalidityState(
                      KYCEDITABLEFIELDS.BirthCountry,
                      person?.personalInformation?.birthCountryIsoCd
                    ) as boolean
                  }
                >
                  <ComboInput
                    list-on-open
                    shadow
                    id="input-birthCountry"
                    label={t('basicInformations.birthCountry')}
                    placeholder={t(
                      'basicInformations.placeHolder.birthCountry'
                    )}
                    error-message={t('basicInformations.errors.birthCountry')}
                    value={birthCountryValue}
                    onSelectedList={onSelectBirthCountry}
                    onInputChange={onInputChangeBirthCountry}
                    onLetterChanged={onLetterChangedBirthCountry}
                    items={JSON.stringify(referentiels?.countries)}
                    customValidityPattern={birthCountryCustomPattern}
                    invalid={
                      getInvalidityState(
                        KYCEDITABLEFIELDS.BirthCountry,
                        person?.personalInformation?.birthCountryIsoCd
                      ) || null
                    }
                  />
                </InputWrapper>
              )}
              {fields.includes(INFORMATIONPAGE.KYCDPTNAI) && isBirthCountryFR && (
                <InputWrapper
                  id={KYCEDITABLEFIELDS.BirthDepartment}
                  aria-invalid={
                    getInvalidityState(
                      KYCEDITABLEFIELDS.BirthDepartment,
                      person?.personalInformation?.birthDepartment
                    ) as boolean
                  }
                >
                  <ComboInput
                    list-on-open
                    shadow
                    id="input-birthDepartment"
                    label={t('basicInformations.birthDepartment')}
                    placeholder={t(
                      'basicInformations.placeHolder.birthDepartment'
                    )}
                    value={birthDepartmentValue}
                    error-message={t(
                      'basicInformations.errors.birthDepartment'
                    )}
                    items={JSON.stringify(referentiels?.departements)}
                    onSelectedList={onChangeBirhtDepartment}
                    onInputChange={onChangeBirhtDepartment}
                    onLetterChanged={onChangeBirhtDepartment}
                    customValidityPattern={birthDepartmentCustomPattern}
                    invalid={
                      getInvalidityState(
                        KYCEDITABLEFIELDS.BirthDepartment,
                        person?.personalInformation?.birthDepartment
                      ) || null
                    }
                  />
                </InputWrapper>
              )}
              {fields.includes(INFORMATIONPAGE.KYCVILNAI) && (
                <InputWrapper
                  id={KYCEDITABLEFIELDS.BirthCity}
                  aria-invalid={
                    getInvalidityState(
                      KYCEDITABLEFIELDS.BirthCity,
                      person?.personalInformation?.[KYCEDITABLEFIELDS.BirthCity]
                    ) as boolean
                  }
                >
                  {isBirthCountryFR ? (
                    <ComboInput
                      list-on-open
                      shadow
                      id="input-birthCity"
                      label={t('basicInformations.birthCity')}
                      placeholder={t('basicInformations.placeHolder.birthCity')}
                      error-message={t(
                        'basicInformations.errors.birthCity.required'
                      )}
                      value={birthCityValue}
                      items={JSON.stringify(referentiels?.cities)}
                      ref={cityRef}
                      customValidityPattern={customValidityPatternBirthCityFr}
                      onInputChange={onChangeBirhtCity}
                      onLetterChanged={onChangeBirhtCity}
                      onSelectedList={onChangeBirhtCity}
                      invalid={
                        getInvalidityState(
                          KYCEDITABLEFIELDS.BirthCity,
                          person?.personalInformation?.[
                            KYCEDITABLEFIELDS.BirthCity
                          ]
                        ) || null
                      }
                    />
                  ) : (
                    <BaseInput
                      id="input-birthCity"
                      label={t('basicInformations.birthCity')}
                      placeholder={t('basicInformations.placeHolder.birthCity')}
                      error-message={t(
                        getCityErrorMessage(
                          person?.personalInformation?.[
                            KYCEDITABLEFIELDS.BirthCity
                          ]
                        )
                      )}
                      ref={freeCityRef}
                      value={birthCityValue}
                      onLetterChanged={onChangeBirhtCity}
                      onInputChange={onChangeBirhtCity}
                      replacement={JSON.stringify(replacement)}
                      invalid={
                        getInvalidityState(
                          KYCEDITABLEFIELDS.BirthCity,
                          person?.personalInformation?.[
                            KYCEDITABLEFIELDS.BirthCity
                          ]
                        ) || null
                      }
                      customValidityPattern={
                        customValidityPatternBirthCityNotFr
                      }
                      maxlength={30}
                    />
                  )}
                </InputWrapper>
              )}
            </>
          )}
          {fields.includes(INFORMATIONPAGE.KYCNATIO) && (
            <LastInputWrapper
              id={KYCEDITABLEFIELDS.NationalityIsoCd}
              aria-invalid={
                getInvalidityState(
                  KYCEDITABLEFIELDS.NationalityIsoCd,
                  person?.personalInformation?.[
                    KYCEDITABLEFIELDS.NationalityIsoCd
                  ]
                ) as boolean
              }
            >
              <ComboInput
                list-on-open
                shadow
                id="input-nationality"
                label={t('basicInformations.nationality')}
                placeholder={t('basicInformations.placeHolder.nationality')}
                value={nationalityIsoCd}
                customValidityPattern={nationalityCustomValidityPattern}
                error-message={t('basicInformations.errors.nationalityIsoCd')}
                onInputChange={onChangeNationality}
                onLetterChanged={onChangeNationality}
                onSelectedList={onChangeNationality}
                items={JSON.stringify(referentiels?.nationalities)}
                invalid={
                  getInvalidityState(
                    KYCEDITABLEFIELDS.NationalityIsoCd,
                    person?.personalInformation?.[
                      KYCEDITABLEFIELDS.NationalityIsoCd
                    ]
                  ) as boolean
                }
              />
            </LastInputWrapper>
          )}
          {fields.includes(INFORMATIONPAGE.SECTEUR) && (
            <>
              <BoldTitleWrapper>
                {t('professionalSituation.title')}
              </BoldTitleWrapper>
              {isDesktop ? (
                <LastInputWrapper
                  id={KYCEDITABLEFIELDS.ActivitySector}
                  aria-invalid={
                    getInvalidityState(
                      KYCEDITABLEFIELDS.ActivitySector,
                      person?.profession?.ihmSector
                    ) as boolean
                  }
                >
                  <DesktopSelect
                    id="input-activitySector"
                    options={sectors}
                    label={t('incomesAndExpenses.form.fields.activitySectors')}
                    placeholder={t(
                      'incomesAndExpenses.form.placeholder.activitySectors'
                    )}
                    isError={
                      getInvalidityState(
                        KYCEDITABLEFIELDS.ActivitySector,
                        person?.profession?.ihmSector
                      ) as boolean
                    }
                    defaultValue={person?.profession?.ihmSector}
                    onChange={onActivitySectorChange}
                  />
                </LastInputWrapper>
              ) : (
                <SectorListWrapper
                  id="activitySector"
                  aria-invalid={
                    getInvalidityState(
                      KYCEDITABLEFIELDS.ActivitySector,
                      person?.profession?.ihmSector
                    ) as boolean
                  }
                >
                  <ModalSelect
                    id="input-activitySector"
                    label={t('incomesAndExpenses.form.fields.activitySectors')}
                    value={person?.profession?.ihmSector}
                    errorMessage={t(
                      'incomesAndExpenses.form.errors.activitySectors'
                    )}
                    list={mobileSectors}
                    onChange={onChangeSector}
                    invalid={
                      invalidityFieldsStates[KYCEDITABLEFIELDS.ActivitySector]
                    }
                    placeholder={t(
                      'incomesAndExpenses.form.placeHolder.activitySector'
                    )}
                    selectionTitle={t(
                      'incomesAndExpenses.form.sectorList.title'
                    )}
                    showErrorMessage
                    required
                  />
                </SectorListWrapper>
              )}
            </>
          )}
          {fields.includes(INFORMATIONPAGE.KYCBLOC2) &&
            fields.includes(INFORMATIONPAGE.KYCBLOC3) && (
              <CustomerInformationnCard
                props={{
                  id: 'person-information-form',
                  firstName:
                    capitalize(person?.personalInformation?.firstName) ?? '',
                  lastName: capitalize(person?.personalInformation?.name) ?? '',
                  adsress: person?.address.streetAddress ?? '',
                  zipCode: person?.address.zipCd ?? '',
                  city: person?.address.city ?? '',
                  counrty: person?.address.countryCd ?? '',
                  email: person?.email.address ?? '',
                  cellPhone: phoneFormatter(person?.cellPhone.phoneNb) ?? '',
                  onButtonClick: openPopUpEdit,
                }}
              />
            )}
          {/* OPTIN */}
          <InputWrapper>
            <CookiesCheckbox
              id="optin"
              name="optin"
              label={t('basicInformations.cookiesConsent')}
              state={dopcOptin.optin}
              onChange={onUpdateOptin}
            />
          </InputWrapper>
          {/* DOPC */}
          <InputWrapper>
            <CookiesCheckbox
              id="dopc"
              name="dopc"
              label={t('basicInformations.dopcNoConsent')}
              state={dopcOptin.dopc}
              onChange={onUpdateDopc}
            />
          </InputWrapper>
          <ButtonActionWrapper>
            <StyledValidateButtonWrapper
              onClick={onSubmit}
              $isDisabled={!isFormValid}
              id="kyc-validate-button"
            >
              {isActionLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                t('common.validate')
              )}
            </StyledValidateButtonWrapper>
            <StyledBackButtonWrapper>
              <ButtonWrapper className="back-button-wrapper">
                <ButtonLink
                  label={t('common.back')}
                  onClick={openPopUpRetour}
                  id="back-button"
                />
              </ButtonWrapper>
            </StyledBackButtonWrapper>
          </ButtonActionWrapper>
        </>
      </form>
      {showModal && (
        <Modal
          isVisible={showModal}
          isFullScreen={false}
          isClosable={true}
          handleCloseModal={onShowModalFn}
        >
          <ModalWrapper>
            <SVGIconWrapper>
              <base-icon icon="info" />
            </SVGIconWrapper>
            <ContentWrapper>
              <TitleWrapper level={3}>{t('LeavePageEdit.title')}</TitleWrapper>
              <DescriptionWrapper>
                {t('LeavePageEdit.description')}{' '}
                <div className="strong">{t('LeavePageEdit.createNewLoan')}</div>
              </DescriptionWrapper>
            </ContentWrapper>
            <ButtonModalWrapper>
              <StyledValidateButtonWrapper
                onClick={onShowModalFn}
                id="modal-cancel-button"
              >
                {t('common.cancel')}
              </StyledValidateButtonWrapper>
              <StyledValidateButtonWrapper
                onClick={returnToWebMerchant}
                id="modal-edit-button"
              >
                {t('common.iModify')}
              </StyledValidateButtonWrapper>
            </ButtonModalWrapper>
          </ModalWrapper>
        </Modal>
      )}
      {showModalLeave && <ModalLeave close={onShowModalLeaveFn} />}
    </>
  );
};

export default InformationForm;
