import styled from 'styled-components';

export const StyledBackButton = styled.button`
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--main-green-500);
  font-family: 'Open Sans';
  font-weight: bold;
  letter-spacing: 0;
  text-align: end;
  display: grid;
  grid-template-areas: 'a b';
  align-items: end;
  &:before {
    content: url(/icons/back-arrow.svg);
    justify-self: start;
  }
  width: 10rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
  width: 10rem;
`;
