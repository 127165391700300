import { StyledValidateButtonWrapper } from '@vat/utils';
import { styled } from 'styled-components';
import { TAB } from '@sweb-front/utils';

export const CardWithRadioHeader = styled.div<{ $isChecked?: boolean }>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  margin:1.6rem 0;
  gap: 1.6rem;
  border-radius: 8px;
  opacity: 0px;
  background-color: ${(props) => (props.$isChecked ? '#EFF8F0' : '#fff')};

  #title{
    display:flex;
    font-family: 'Ubuntu Bold';
    font-weight:700;
    font-size: 2rem;
    line-height: 2.6rem;
    background-color: ${(props) => (props.$isChecked ? '#EFF8F0' : '#EFF4F2')};
    padding:16px 12px;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
  }

  #container{
    display:flex;
    flex-direction: column;
    gap:1.8rem;
    padding: 0px 16px 8px 16px;
    font-family: Open Sans;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 400;
    line-height: 2rem;

    span{
      font-family: 'Open Sans Bold';
    }

    & a[slot='link'] {
      unde
      font-family: 'Open Sans';
      color: var(--main-green-500);
      margin-bottom: .8rem 0;
      text-decoration-line: underline;

    }
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

export const SVGIconWrapper = styled.div`
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
    margin-right: 1.2rem;
  }
`;

export const StyledContinueButtonWrapper = styled(StyledValidateButtonWrapper)`
  @media screen and ${TAB} {
    width: 50%;
  }
`;
