import { ProgressBarComponent } from 'src/libs/components/RedirectionComponent/styles';
import { BreadcrumbWrapper, StyledBreadcrumb } from './styles';

export type TStep = {
  name: string;
  step: number;
};

export type THeaderProps = {
  steps: TStep[];
  currentStep: number;
};

const Header = ({ steps, currentStep }: THeaderProps) => {
  return (
    <BreadcrumbWrapper id="app-header">
      <ProgressBarComponent
        progress={(currentStep / steps?.length) * 100}
        progressColor={'#268038'}
      >
        <div></div>
      </ProgressBarComponent>
    </BreadcrumbWrapper>
  );
};

export default Header;
