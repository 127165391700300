export const SECTORLIST = [
  'privateEmployeeSector',
  'privateEmployeeNotCDI',
  'publicSector',
  'privatePensioner',
  'dgpdg',
  'craftsmenTradesmen',
  'freelances',
  'publicPensioner',
  'agriculturalSector',
  'invalid',
  'autoEntrepreneur',
  'others',
];

export const SECTORCODES: Record<string, string> = {
  privateEmployeeSector: 'SPRI',
  privateEmployeeNotCDI: 'SPRD',
  publicSector: 'SPU',
  agriculturalSector: 'SAG',
  craftsmenTradesmen: 'ACO',
  freelances: 'PLI',
  privatePensioner: 'AUTRPR',
  publicPensioner: 'AUTRPC',
  invalid: 'AUTI',
  others: 'AUT',
  dgpdg: 'AUTD',
  autoEntrepreneur: 'ACOEN',
};

export const MONTH_INCOME_BASE_CALCULATION = ['12', '13', '14', '15', '16'];
