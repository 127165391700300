import {
  BubbleInfo,
  ButtonLink,
  DesktopSelect,
  GenderInput,
  ModalSelect,
  NumericInput,
  GenderInputDS,
} from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';
import { SOLVAPAGE } from '@vat/configuration';

import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonActionWrapper,
  StyledBackButtonWrapper,
  StyledValidateButtonWrapper,
} from '@vat/utils';
import { isStringEmpty } from '@sweb-front/utils';
import {
  MONTH_INCOME_BASE_CALCULATION,
  SECTORCODES,
  SECTORLIST,
} from './IncomeExpensesConstants';
import useIncomeExpenseForm, { pattern } from './useIncomeExpenseForm';
import {
  BaseMonthIncomeNumberWrapper,
  IncomeDetailsInfoWrapper,
  IncomeWrapper,
  SectorListWrapper,
} from './styles';

export interface IIncomeExpensesFormProps {
  fields: string[];
}

export const replacement = JSON.stringify([
  { regexp: '[^0-9,.]', replacement: '' },
  { regexp: '[.]{1,}', replacement: ',' },
  { regexp: '[,]{1,}', replacement: ',' },
]);

const IncomeExpensesForm = ({ fields }: IIncomeExpensesFormProps) => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const formRef = useRef<HTMLFormElement>(null);
  const baseSalaryMonthRef = useRef<GenderInputDS>(null);
  const {
    isFormValid,
    showBubbleInfo,
    showExpensesBubbleInfo,
    values,
    showBaseMonthIncome,
    invalidityFieldsStates,
    isActionLoading,
    mobileSectors,
    isDesktop,
    onChangeSector,
    onActivitySectorChange,
    getInvalidityState,
    onIncomeChange,
    onIncomeLetterChanged,
    onIncomeFocus,
    onIncomeBlur,
    onExpensesChange,
    onExpensesLetterChanged,
    onExpensesFocus,
    onExpensesBlur,
    onSubmit,
    onChange,
    goBackToStep,
  } = useIncomeExpenseForm(
    opportunity,
    parameters.wayCd,
    formRef,
    baseSalaryMonthRef
  );

  const sectors = useMemo(
    () =>
      SECTORLIST.map((sector) => ({
        value: SECTORCODES[sector],
        label: t(`incomesAndExpenses.form.sectorList.${sector}`),
      })),
    []
  );

  useEffect(() => {
    document.querySelector('#rippleContainer')?.setAttribute('style', '');
  }, []);

  return (
    <form ref={formRef}>
      {fields.includes(SOLVAPAGE.SOLVACT) &&
        (isDesktop ? (
          <DesktopSelect
            id="input-activitySector"
            options={sectors}
            label={t('incomesAndExpenses.form.fields.activitySectors')}
            placeholder={t(
              'incomesAndExpenses.form.placeholder.activitySectors'
            )}
            isError={
              !!getInvalidityState(
                'activitySector',
                values.activitySector,
                invalidityFieldsStates,
                pattern
              ) as boolean
            }
            defaultValue={values.activitySector}
            onChange={onActivitySectorChange}
          />
        ) : (
          <SectorListWrapper
            id="activitySector"
            aria-invalid={
              getInvalidityState(
                'activitySector',
                values.activitySector,
                invalidityFieldsStates,
                pattern
              ) as boolean
            }
          >
            <ModalSelect
              id="input-activitySector"
              label={t('incomesAndExpenses.form.fields.activitySectors')}
              value={values.activitySector}
              errorMessage={t('incomesAndExpenses.form.errors.activitySectors')}
              list={mobileSectors}
              onChange={onChangeSector}
              invalid={invalidityFieldsStates.activitySector}
              placeholder={t(
                'incomesAndExpenses.form.placeHolder.activitySector'
              )}
              key="input-activitySector"
              selectionTitle={t('incomesAndExpenses.form.sectorList.title')}
              bubbleInfoDescription={t('incomesAndExpenses.bubbleInfo')}
              showErrorMessage
              required
            />
          </SectorListWrapper>
        ))}
      {fields.includes(SOLVAPAGE.SOLVREV) && (
        <>
          <IncomeWrapper
            id="monthlyIncomeAndExpense"
            aria-invalid={
              getInvalidityState(
                'monthlyIncomeAndExpense',
                values.monthlyIncomeAndExpense,
                invalidityFieldsStates,
                pattern
              ) as boolean
            }
          >
            <NumericInput
              id="input-monthlyIncomeAndExpense"
              error-message={
                isStringEmpty(values.monthlyIncomeAndExpense)
                  ? t(
                      'incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.required'
                    )
                  : t(
                      'incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format'
                    )
              }
              label={t(
                'incomesAndExpenses.form.fields.monthlyIncomeAndExpenses'
              )}
              value={values.monthlyIncomeAndExpense}
              placeholder={t(
                'incomesAndExpenses.form.placeHolder.monthlyIncomeAndExpenses'
              )}
              customValidityPattern={JSON.stringify([
                {
                  regexp: pattern,
                  errorMessage: t(
                    'incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.format'
                  ),
                },
                {
                  regexp: '.+',
                  errorMessage: t(
                    'incomesAndExpenses.form.errors.monthlyIncomeAndExpenses.required'
                  ),
                },
              ])}
              maxlength={5}
              replacement={replacement}
              onInputChange={onIncomeChange}
              onLetterChanged={onIncomeLetterChanged}
              onFocus={onIncomeFocus}
              onBlur={onIncomeBlur}
              invalid={
                getInvalidityState(
                  'monthlyIncomeAndExpense',
                  values.monthlyIncomeAndExpense,
                  invalidityFieldsStates,
                  pattern
                ) || null
              }
              with-round
              can-delete
              with-reset
            />
            {showBubbleInfo && (
              <IncomeDetailsInfoWrapper>
                <BubbleInfo
                  id="bubbleinfo-income"
                  title={t('incomesAndExpenses.form.bubbleInfos.report')}
                  bubbleItems={[
                    t('incomesAndExpenses.form.bubbleInfos.incomeAndBonus'),
                    t('incomesAndExpenses.form.bubbleInfos.retirements'),
                    t('incomesAndExpenses.form.bubbleInfos.freelanceincomes'),
                    t('incomesAndExpenses.form.bubbleInfos.pensions'),
                  ]}
                  isCustomedMarker={false}
                  marker="default"
                  hasIcon={true}
                />
              </IncomeDetailsInfoWrapper>
            )}
          </IncomeWrapper>

          {showBaseMonthIncome && (
            <BaseMonthIncomeNumberWrapper
              id="monthNumberBaseCalculation"
              aria-invalid={
                getInvalidityState(
                  'monthNumberBaseCalculation',
                  values.monthNumberBaseCalculation,
                  invalidityFieldsStates,
                  pattern
                ) as boolean
              }
            >
              <GenderInput
                ref={baseSalaryMonthRef}
                id="input-monthNumberBaseCalculation"
                label={t(
                  'incomesAndExpenses.form.fields.monthNumberBaseCalculation'
                )}
                value={values.monthNumberBaseCalculation}
                error-message={t(
                  'incomesAndExpenses.form.errors.monthNumberBaseCalculation'
                )}
                invalid={
                  (getInvalidityState(
                    'monthNumberBaseCalculation',
                    values.monthNumberBaseCalculation,
                    invalidityFieldsStates,
                    pattern
                  ) as boolean) || null
                }
                onSelectedElementChange={(e) =>
                  onChange(e, 'monthNumberBaseCalculation')
                }
                hasFocus
              >
                {MONTH_INCOME_BASE_CALCULATION.map((month: string) => (
                  <selected-button
                    value={month}
                    secondary="true"
                    selected={values.monthNumberBaseCalculation === month}
                    key={`month_number_${month}`}
                  >
                    {month}
                  </selected-button>
                ))}
              </GenderInput>
            </BaseMonthIncomeNumberWrapper>
          )}
        </>
      )}
      {fields.includes(SOLVAPAGE.SOLVCHRGS) && (
        <IncomeWrapper
          id="monthlyExpenses"
          aria-invalid={
            getInvalidityState(
              'monthlyExpenses',
              values.monthlyExpenses,
              invalidityFieldsStates,
              pattern
            ) as boolean
          }
        >
          <NumericInput
            id="input-monthlyExpenses"
            error-message={
              isStringEmpty(values.monthlyExpenses)
                ? t('incomesAndExpenses.form.errors.monthlyExpenses.required')
                : t('incomesAndExpenses.form.errors.monthlyExpenses.format')
            }
            label={t('incomesAndExpenses.form.fields.monthlyExpenses')}
            value={values.monthlyExpenses}
            placeholder={t(
              'incomesAndExpenses.form.placeHolder.monthlyExpenses'
            )}
            customValidityPattern={JSON.stringify([
              {
                regexp: pattern,
                errorMessage: t(
                  'incomesAndExpenses.form.errors.monthlyExpenses.format'
                ),
              },
              {
                regexp: '.+',
                errorMessage: t(
                  'incomesAndExpenses.form.errors.monthlyExpenses.required'
                ),
              },
            ])}
            maxlength={5}
            replacement={replacement}
            onInputChange={onExpensesChange}
            onLetterChanged={onExpensesLetterChanged}
            onFocus={onExpensesFocus}
            onBlur={onExpensesBlur}
            invalid={
              getInvalidityState(
                'monthlyExpenses',
                values.monthlyExpenses,
                invalidityFieldsStates,
                pattern
              ) || null
            }
            with-round
            can-delete
            with-reset
          />
          {showExpensesBubbleInfo && (
            <IncomeDetailsInfoWrapper>
              <BubbleInfo
                id="bubbleInfo-monthlyExpenses"
                title={t('incomesAndExpenses.form.bubbleInfos.declareOnly')}
                bubbleItems={[
                  t(
                    'incomesAndExpenses.form.bubbleInfos.rentOrMonthlyMortagePayement'
                  ),
                  t(
                    'incomesAndExpenses.form.bubbleInfos.loanInProgressNotRealEstate'
                  ),
                  t('incomesAndExpenses.form.bubbleInfos.alimonyPaid'),
                  t('incomesAndExpenses.form.bubbleInfos.careExpenses'),
                ]}
                isCustomedMarker={false}
                hasIcon={true}
                marker={'default'}
              />
            </IncomeDetailsInfoWrapper>
          )}
        </IncomeWrapper>
      )}
      <ButtonActionWrapper>
        <StyledValidateButtonWrapper
          onClick={onSubmit}
          $isDisabled={!isFormValid}
          id="solva-validate-button"
          className={!isFormValid ? 'isDisabled' : ''}
        >
          {isActionLoading ? (
            <StyledButtonLoader
              isLoading
              isTextInline
              isHideBackground={false}
            />
          ) : (
            t('common.validate')
          )}
        </StyledValidateButtonWrapper>
        <StyledBackButtonWrapper>
          <ButtonWrapper className="back-button-wrapper">
            <ButtonLink
              label={t('common.back')}
              onClick={goBackToStep}
              id="back-button"
            />
          </ButtonWrapper>
        </StyledBackButtonWrapper>
      </ButtonActionWrapper>
    </form>
  );
};

export default IncomeExpensesForm;
