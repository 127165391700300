import {
  ContentWrapper,
  DescriptionWrapper,
  NumberWrapper,
  NumericListWrapper,
  TitleWrapper,
} from './styles';

export interface INumericListProps {
  listIndex: string;
  title?: string;
  description?: string;
  id?: string;
}

const NumericList = ({
  listIndex,
  title,
  description,
  id,
}: INumericListProps) => {
  return (
    <NumericListWrapper className="numeric-list" id={id}>
      <NumberWrapper>{listIndex}</NumberWrapper>
      <ContentWrapper>
        {title && <TitleWrapper>{title}</TitleWrapper>}
        {description && <DescriptionWrapper>{description}</DescriptionWrapper>}
      </ContentWrapper>
    </NumericListWrapper>
  );
};

export default NumericList;
