import { Trans, useTranslation } from 'react-i18next';
import { CardWithRadioHeader, DetailWrapper, SVGIconWrapper } from './style';
import { RadioButton } from '@sweb-front/components';

interface ICardProps {
  onSelect: (id: String) => void;
  isSelected: boolean;
  openModal?: () => void;
}

const AutomaticAnalysysMethodCard = (props: ICardProps) => {
  const { t } = useTranslation();
  return (
    <CardWithRadioHeader $isChecked={props.isSelected}>
      <div id="title" onClick={() => props.onSelect('AUTO')}>
        <RadioButton
          children={''}
          id={'auto-radio-title-button'}
          name={''}
          checked={props.isSelected}
        />
        {t('openBanking.formVatRib.analyseMethod.radio')}
      </div>
      <div id="container">
        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="lock-close" />
          </SVGIconWrapper>
          <div>
            <Trans
              i18nKey="openBanking.formVatRib.analyseMethod.banque"
              components={{
                connex: <span>iban</span>,
                revenus: <span>percevez vos revenus.</span>,
              }}
            />
          </div>
        </DetailWrapper>
        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="contract-electronic" />
          </SVGIconWrapper>
          <div>
            <Trans
              i18nKey="openBanking.formVatRib.analyseMethod.iban"
              components={{
                aut: <span>automatique</span>,
                iban: <span>iban</span>,
              }}
            />
          </div>
        </DetailWrapper>

        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="bank" />
          </SVGIconWrapper>
          <div>
            <Trans
              i18nKey="openBanking.formVatRib.analyseMethod.historique"
              components={{
                aut: <span>automatique</span>,
                hist: <span>historique</span>,
              }}
            />
          </div>
        </DetailWrapper>

        <div>
          <a slot="link" onClick={props.openModal}>
            {'Quelles sont les données récupérées ?'}
          </a>
        </div>
      </div>
    </CardWithRadioHeader>
  );
};

export default AutomaticAnalysysMethodCard;
