import { ImgWrapper } from './styles';

export const ErrorDeskImage = () => {
  return (
    <ImgWrapper>
      <img src='images/creditoError.png' alt='Image credito' />
    </ImgWrapper>
  );
};

export default ErrorDeskImage;
