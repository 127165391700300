import {
  IBankCardPaymentRequest,
  IDoAuthorizationRequest,
  IBankCardPaymentResponse,
  IDsCardPaymentResponse,
  IDoAuthorizationResponse,
  IOnboardingEsignResponse,
} from '@sweb-front/types';
import axiosInstance from './httpCommon';
import { AxiosHeaderValue } from 'axios';

export interface OnboardingUrlResponse {
  url: string;
}

export const updateCardPayment = async (
  cardId: string,
  cardRef: string,
  returnCode: string,
  returnValue: string,
  successCallback?: (res: IBankCardPaymentResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const updateCardPaymentRequest: IBankCardPaymentRequest = {
      cardId,
      cardRef,
      cardCreationCd: returnCode,
      cardCreationValue: returnValue,
    };
    const response = await axiosInstance().post(
      `vendors/opportunities/v1/payment/bank-card`,
      updateCardPaymentRequest
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const getOnboardingUrl = async (
  returnUrl: string,
  successCallback?: (response: OnboardingUrlResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().get(
      `vendors/opportunities/v1/onboarding?returnUrl=${returnUrl}`
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const getDsCardUrl = async (
  returnUrl: string,
  successCallback?: (response: IDsCardPaymentResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().get(
      `vendors/opportunities/v1/card3ds?returnUrl=${returnUrl}`
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const check3DSReturnCode = async (
  onboardingErrorCd: string,
  successCallback?: (response: IOnboardingEsignResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().post(
      `vendors/opportunities/v1/onboarding/esign`,
      {
        onboardingErrorCd,
      }
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const doAuthorization = async (
  threeDsRef: string,
  cardCreationCd: string,
  cardCreationValue: string,
  successCallback?: (response: IDoAuthorizationResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const doAuthorizationRequest: IDoAuthorizationRequest = {
      threeDsRef,
      cardCreationCd,
      cardCreationValue,
    };
    const response = await axiosInstance().post(
      `vendors/opportunities/v1/payment/doAuthorization`,
      doAuthorizationRequest
    );
    successCallback?.(response?.data);
    return response?.data;
  } catch (errCode) {
    errorCallback?.(errCode as number);
    throw new Error(errCode);
  }
};

export const abortOpportunity = async (token: string, reason?: string) => {
  return axiosInstance().post(
    `vendors/opportunities/v1/opportunity/abort`,
    {
      reason: (reason ?? '').replace(', $OtherReasons', ''),
    },
    { headers: { token } }
  );
};

export const getAcceptationPolicyPromise = (
  paymentMode: 'RIB' | 'CB',
  step: 'KYC' | 'SOLVA' | 'ASSU' | 'CB' | 'ONBO' | 'BANK_3DS' | 'DECISION_RVO'
) => {
  return axiosInstance().get(
    `vendors/opportunities/v1/opportunity/score?paymentMode=${paymentMode}&step=${step}`
  );
};

export const createECard = async (token: string) => {
  return axiosInstance().post(`vendors/opportunities/v1/e-card`, {
    headers: { token },
  });
};

export const saveXtn = async (eventId: string) => {
  try {
    return await axiosInstance().post(
      'vendors/opportunities/v1/xtn/xtn-event',
      {
        eventId,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        headers: {
          headers: {
            customHeader: 'souscription',
          } as unknown as AxiosHeaderValue,
        },
      }
    );
  } catch {
    console.warn('XTN::xtnEvent save xtn failed to execute');
  }
};
