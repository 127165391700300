import {
  computePageName,
  STOREDVATTYPE,
  URL_MAPPING,
} from '@vat/configuration';
import { IStep } from '@sweb-front/store';
import { ascSort } from '@sweb-front/utils';
import { useTranslation } from 'react-i18next';
import { fetchParametersFromBase } from 'src/services/serviceParameters';
import { useCallback } from 'react';

const useCreditParameters = () => {
  const { t } = useTranslation();
  // Your original logic for fetching parameters from the base
  const recalculateCreditParameters = useCallback(
    async (
      distributorNb: string,
      overdraftAmt: string,
      productTypeCd: string,
      openBankingChoice: string,
      openBankingStatus: string
    ) => {
      try {
        const result = await fetchParametersFromBase(
          distributorNb,
          overdraftAmt?.toString(),
          productTypeCd,
          openBankingChoice,
          openBankingStatus
        );
        const appSteps: IStep[] = result.displays
          .map(
            (
              { displayCd, oderNumber }: Record<string, string>,
              index: number
            ) => {
              const page = computePageName(displayCd);
              const paths =
                index === 0
                  ? [URL_MAPPING[page].url, '/']
                  : [URL_MAPPING[page].url];
              return {
                id: oderNumber,
                step: index + 1,
                stepTitle: t(`${URL_MAPPING[page].key_label}.title`),
                isSubStep: !!URL_MAPPING[page].isSubStep,
                label: t(`${URL_MAPPING[page].key_label}.stepTitle`),
                paths,
                components: URL_MAPPING[page].components,
              };
            }
          )
          .sort((stepA: IStep, stepB: IStep) =>
            ascSort(stepA.step, stepB.step)
          );
        localStorage.setItem(STOREDVATTYPE, result?.wayCd ?? '');
        return { result, appSteps };
      } catch (error) {
        console.error('Error when calling paramaters service');
        throw error;
      }
    },
    []
  );

  return { recalculateCreditParameters };
};

export default useCreditParameters;
