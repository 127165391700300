import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledBackButton } from './Style';

export const BackButton = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const onClick = () => {
    history(-1);
  };
  return (
    <StyledBackButton onClick={onClick} type="button">
      {t('common.back')}
    </StyledBackButton>
  );
};

export default BackButton;
