import styled from 'styled-components';
import Title from '../Title';

export const LabelWrapper = styled.div`
  margin-bottom: 0.8rem;
  text-align: initial;
  font-family: 'Open Sans';
  line-height: 2.4rem;
  font-size: 1.6rem;
`;

export const ChevronWrapper = styled.div<{
  invalid?: boolean | null;
  validity?: boolean | null;
  id?: string;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  vertical-align: center;
  border: 1px solid var(--neutrals-400);
  border-radius: 0.4rem;
  height: 4.8rem;
  justify-content: space-between;
  cursor: pointer;
  ${({ invalid, validity }) => {
    if (invalid === null) {
      return `1px solid var(--neutrals-400);`;
    }
    if (invalid || validity === false) {
      return `border: 1px solid var(--red-500);`;
    }
    if (!invalid || validity === true) {
      return `border: 1px solid var(--success-500);`;
    }
  }}
`;

export const SelectInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;
  width: calc(100% - 5.6rem);
  & span {
    display: inline-block;
    height: 2.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--neutrals-500);
    font-size: 1.6rem;
  }
  & span.placeholder {
    color: var(--neutrals-300);
  }
`;

export const IconWrapper = styled.div`
  width: auto;
  margin: 0;
  padding: 0;
  margin-right: 0.4rem;
  display: flex;
  flex-direction: row;
  block-size: fit-content;
`;

export const SVGIconWrapper = styled.div<{
  id?: string;
  invalid?: boolean | null;
  validity?: boolean | null;
}>`
  align-self: center;
  & base-icon {
    margin-right: 0.4rem;
    --color-icon: var(--neutrals-400);
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;

    ${({ validity }) => {
      if (validity === null || validity === undefined) {
        return `--color-icon: var(--neutrals-400);`;
      }
      if (validity === true) {
        return `--color-icon: var(--success-500);`;
      }
      return `--color-icon: var(--red-500);`;
    }}
  }
`;

export const TitleWrapper = styled(Title)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0 0 1.2rem 0;
`;
export const ErrorMessageWrapper = styled.div`
  font-family: 'Open Sans';
  color: var(--red-500);
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0.4rem 0 0 0;
  padding: 0;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.6rem;
  & gender-input {
    & selected-button {
      text-align: center;
      word-break: break-word;
      font-family: 'Open Sans';
      flex-grow: 1;
      min-height: 5.6rem;
      width: 100%;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 0.8rem;
      &:last-of-type {
        margin-bottom: 1.6rem;
      }
    }
  }
  & span {
    color: var(--red-500);
  }
`;
