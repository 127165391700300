import { Section } from '@sweb-front/components';
import { useTranslation } from 'react-i18next';
import { IncomesExpensesLongForm } from '@vat/containers';
import { SOLVALONG } from '@vat/configuration';
import { usePageRedirection } from '@sweb-front/hooks';

export const IncomesExpensesLong = () => {
  const { t } = useTranslation();
  const { isPageLoading } = usePageRedirection(SOLVALONG);

  return (
    !isPageLoading && (
      <Section title={t('incomeAndExpensesLong.title')}>
        <IncomesExpensesLongForm />
      </Section>
    )
  );
};
