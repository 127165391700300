import { DESKTOP, TAB } from "@sweb-front/utils";
import styled from "styled-components";

export const InputWrapper = styled.div`
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  & div {
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-align: left;
  }
`;
