import { getResultOpenBanking } from '@vat/services';
import { useNavigate } from 'react-router-dom';
import {
  ERRORPAGE,
  INSURANCE,
  OBCONNEXIONERROR,
  OPENBANKING,
  PIECESJUSTIFICATIVES,
  SITUATIONFAMILIALE,
} from '@vat/configuration';
import {
  fetchWay,
  selectSteps,
  setSteps,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { useTranslation } from 'react-i18next';
import useCreditParameters from './useCreditParameters';
import { useCloseOpportunity } from '@vat/utils';

const useObResultTreatment = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { recalculateCreditParameters } = useCreditParameters();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const steps = useAppSelector(selectSteps);
  const containsINSURANCE = steps?.find((stp) =>
    stp.paths?.includes(INSURANCE)
  );

  const processObResult = async () => {
    try {
      const status = await getResultOpenBanking();
      if ((status?.statusOb ?? '').toLowerCase() === 'ok') {
        dispatch(
          updateSteps({
            externalAppName: 'openBanking',
            isExternalUrlAlreadyVisited: true,
            isSeDone: true,
            resultOb: 'OK',
          })
        );
        dispatch(
          updateParcoursNavigation({
            name: OPENBANKING,
            actionPageDone: true,
          })
        );
        navigate(containsINSURANCE ? INSURANCE : PIECESJUSTIFICATIVES);
      } else {
        try {
          const { overdraftAmt } =
            opportunity?.offers?.[0]?.loans?.[0]?.financialDetails;
          const { distributorNb } = opportunity?.distributor;
          const { productTypeCd } =
            opportunity?.offers?.[0]?.loans?.[0]?.financialProduct;

          const { appSteps, result } = await recalculateCreditParameters(
            distributorNb,
            overdraftAmt.toString(),
            productTypeCd,
            'O',
            'KO'
          );

          if (appSteps && appSteps.length > 0) {
            dispatch(fetchWay(result));
            // remove isSubStep if it is the first page and replace the situation step by informations on the stepLine
            const firstStep = appSteps.find((st) => st.step === 1);
            if (firstStep && firstStep.paths.includes(SITUATIONFAMILIALE)) {
              firstStep.label = t('basicInformations.stepTitle');
            }
            delete firstStep.isSubStep;
            dispatch(setSteps(appSteps));
            dispatch(
              updateSteps({
                externalAppName: 'openBanking',
                isSeDone: true,
                resultOb: 'KO',
              })
            );
            dispatch(
              updateParcoursNavigation({
                name: OPENBANKING,
                actionPageDone: true,
              })
            );
            navigate('/');
          } else {
            dispatch(
              updateSteps({
                externalAppName: 'openBanking',
                isErrorHappened: true,
                resultOb: 'KO',
              })
            );
            updateAbortErrorMessage(
              'OpenBanking::fetchParameters en mode automatique, les étapes du parcours sont vides'
            );
            dispatch(
              updateParcoursNavigation({
                name: OPENBANKING,
                actionPageDone: false,
                params: {
                  hasError: true,
                },
              })
            );
            navigate(ERRORPAGE, {
              replace: true,
            });
          }
        } catch {
          dispatch(
            updateParcoursNavigation({
              name: OPENBANKING,
              actionPageDone: false,
              params: {
                hasError: true,
              },
            })
          );
          dispatch(
            updateSteps({
              externalAppName: 'openBanking',
              isErrorHappened: true,
              resultOb: 'KO',
            })
          );
          updateAbortErrorMessage(
            'OpenBanking::fetchParameters recalcul parcours rencontre une erreur'
          );
          navigate(ERRORPAGE);
        }
      }
    } catch {
      dispatch(
        updateSteps({
          externalAppName: 'openBanking',
          resultOb: 'KO',
          isSeDone: true,
        })
      );
      dispatch(
        updateParcoursNavigation({
          name: OPENBANKING,
          actionPageDone: true,
          params: {
            getResult: 'ERROR',
          },
        })
      );
      navigate(OBCONNEXIONERROR, {
        replace: true,
      });
    }
  };

  return {
    processObResult,
  };
};

export default useObResultTreatment;
