import { Breadcrumb } from '@sweb-front/components';
import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.8rem 0 2.8rem 0;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  max-width: 70.1rem;
  flex-grow: 1;
`;
