export const cetelem = {
  logo: 'cetelem',
  colors: {
    primary: '#3A913F',
    title: '#3A913F',
    light: '#E8F5E9',
    lightest: '#F5F9F6',
  },
  colorsDemat: {
    breadCrumbs: '#3A913F',
    titlesText: '#3A913F',
    nextButton: '#3A913F',
    elementsColor: '#3A913F',
    backGroundColor: '#E8F5E9',
    logosColor: '#3A913F',
  },
};


export const none = {
  logo: '',
  colors: {
    primary: '#151515',
    title: '#151515',
    light: '#F2F2F2',
    lightest: '#FAFAFA',
  },
  colorsDemat: {
    breadCrumbs: '#151515',
    titlesText: '#151515',
    nextButton: '#151515',
    elementsColor: '#151515',
    backGroundColor: '#F2F2F2',
    logosColor: '#151515',
  },
};
