import React, { useState } from 'react';
import { CardWithRadioHeader, DetailWrapper, SVGIconWrapper } from './style';
import { RadioButton } from '@sweb-front/components';
import { Trans, useTranslation } from 'react-i18next';

interface ICardProps {
  onSelect: (id: String) => void;
  isSelected: boolean;
}
const StandardMethodCard = (props: ICardProps) => {
  const { t } = useTranslation();
  return (
    <CardWithRadioHeader $isChecked={props.isSelected}>
      <div id="title" onClick={() => props.onSelect('STANDARD')}>
        <RadioButton
          children={''}
          id={''}
          name={''}
          checked={props.isSelected}
        />
        {t('openBanking.formVatRib.manuelMethod.radio')}
      </div>
      <div id="container">
        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="contract" />
          </SVGIconWrapper>
          <div>{t('openBanking.formVatRib.manuelMethod.formulaire')}</div>
        </DetailWrapper>

        <DetailWrapper>
          <SVGIconWrapper>
            <base-icon icon="contract-electronic" />
          </SVGIconWrapper>
          <div>{t('openBanking.formVatRib.manuelMethod.modePayment')}</div>
        </DetailWrapper>
      </div>
    </CardWithRadioHeader>
  );
};

export default StandardMethodCard;
