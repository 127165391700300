import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledContainer,
  StyledErrorMessage,
  StyledLabel,
  StyledOption,
  StyledOptions,
  StyledSelectComponent,
  StyledSelectContainer,
  StyledSelectContent,
} from './Style';
import CheckMarkIcon from './CheckMarkIcon';

type SelectOption = {
  value: string;
  label: string;
};

export type DesktopSelectProps = {
  id?: string;
  options: SelectOption[];
  placeholder?: string;
  defaultValue?: string;
  label?: string;
  isError?: boolean;
  onChange?: (value: string) => void;
  errMsg?: string;
};

export const DesktopSelect = ({
  id,
  options,
  label,
  placeholder,
  defaultValue,
  isError,
  errMsg,
  onChange,
}: DesktopSelectProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(defaultValue);
  const toogleMenuOpened = () => setOpened((v) => !v);
  const onSelectClick: MouseEventHandler = () => {
    toogleMenuOpened();
  };
  const onOptionClick = (e: MouseEvent, v: string) => {
    e.preventDefault();
    if (selected === v) {
      toogleMenuOpened();
      return;
    }
    setSelected(v);
    if (onChange) {
      onChange(v);
    }
    toogleMenuOpened();
  };

  const selectedLabel = options.find(
    ({ value }) => value === defaultValue
  )?.label;

  return (
    <StyledContainer onBlur={() => setOpened(false)} id={id} isError={isError}>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelectContainer>
        <StyledSelectComponent
          opened={opened}
          tabIndex={0}
          onClick={onSelectClick}
          type="button"
          isError={!!isError}
        >
          <StyledSelectContent>
            <span className="d-label">{selectedLabel ?? placeholder}</span>
            <span className="d-icon">
              {selectedLabel ? <CheckMarkIcon /> : <></>}
            </span>
          </StyledSelectContent>
        </StyledSelectComponent>
        <StyledOptions opened={opened}>
          {options.map((option, index) => (
            <StyledOption
              key={`${option}${index + 1}`}
              value={option.value}
              onMouseDown={(e) =>
                onOptionClick(e as unknown as MouseEvent, option.value)
              }
            >
              {option.label}
            </StyledOption>
          ))}
        </StyledOptions>
        {isError && (
          <StyledErrorMessage>
            {errMsg ?? t('incomesAndExpenses.form.errors.activitySectors')}
          </StyledErrorMessage>
        )}
      </StyledSelectContainer>
    </StyledContainer>
  );
};

export default DesktopSelect;
