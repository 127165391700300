import { ProfessionalForm } from '@vat/containers';
import { useTranslation } from 'react-i18next';
import MainSectionWrapper from './styles';
import { SITUATIONPROF } from '@vat/configuration';
import { usePageRedirection } from '@sweb-front/hooks';

const ProfessionalSituation = () => {
  const { t } = useTranslation();
  const { isPageLoading } = usePageRedirection(SITUATIONPROF);

  return (
    !isPageLoading && (
      <MainSectionWrapper title={t('professionalSituation.title')}>
        <ProfessionalForm />
      </MainSectionWrapper>
    )
  );
};

export default ProfessionalSituation;
