import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  font-family: "Open Sans";
  border-top: 0.1rem solid var(--neutrals-100);
  font-size: 12px;
  line-height: 14px;
  color: #61676c;
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  padding: 1.7rem 0;
  margin: 1rem 0;
`;

export const FooterStyledSpan = styled.span`
  color: #61676c;
  font-family: "Open Sans Bold";
`;

export const FooterStyledLink = styled(Link)`
  text-decoration: underline;
  color: #61676c;
`;

export const FooterStyledNativeLink = styled.a`
  text-decoration: underline;
  color: #61676c;
`;

export const FooterStyledParagraph = styled.p`
  font-family: "Open Sans";
  // text-align: justify;
  text-justify: inter-word;
  margin: 0;
`;
