import { Section } from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import { ascSort } from '@sweb-front/utils';
import { FORM_FIELDS_MAPPING, INFORMATIONS } from '@vat/configuration';
import { InformationForm } from '@vat/containers';
import { selectDisplays } from '@sweb-front/store';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePageRedirection } from '@sweb-front/hooks';

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Information = () => {
  const { t } = useTranslation();
  const displays = useAppSelector(selectDisplays);
  const kycFields = Object.keys(FORM_FIELDS_MAPPING.KYC).sort((k, l) =>
    ascSort(FORM_FIELDS_MAPPING.KYC[k], FORM_FIELDS_MAPPING.KYC[l])
  );
  const { isPageLoading } = usePageRedirection(INFORMATIONS);

  const [getInformationFields] = (displays ?? [])
    .filter((d) => d.displayCd === 'KYC')
    .map((df) => df.fields.split(','));

  const fields = kycFields.filter((f) =>
    (getInformationFields ?? []).includes(f)
  );

  return (
    !isPageLoading && (
      <SectionContainer id="section-container">
        <Section id="section" title={t('basicInformations.title')}>
          <InformationForm fields={fields} />
        </Section>
      </SectionContainer>
    )
  );
};

export default Information;
