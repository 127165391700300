import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@sweb-front/store';
import useRefusedPayment from './useRefusedPayment';
import {
  ContentWrapper,
  SectionWrapper,
  SVGIconWrapper,
  TitleCmp,
  Paragraph,
  ContentParagraph,
  BoldParagraph,
} from './styles';

const RefusedPayment = () => {
  const { t } = useTranslation();
  const parameters = useAppSelector((state) => state.parameters.state);
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { wayType } = useRefusedPayment(opportunity, parameters);

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <base-icon icon="cross circle" />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('responses.refused.title')}</TitleCmp>
        <Paragraph>{t('responses.refused.insufficientRessources')}</Paragraph>
        {wayType === 'VATC' && (
          <BoldParagraph>
            {t('responses.refused.securedBankInformations')}
          </BoldParagraph>
        )}
        <ContentParagraph>
          {t('responses.refused.otherPaymentWay')}
        </ContentParagraph>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default RefusedPayment;
