import { useBreakpoints } from '@sweb-front/hooks';
import {
  Steps,
  PastStep,
  CurrentStep,
  StyledCheckIcon,
  FuturStep,
  Wrapper,
  Circle,
  Label,
  SmallCircle,
  FullCircle,
  FirstLabel,
  LastLabel,
  FuturLabel,
} from './styles';

interface Step {
  label: string;
}

export interface IBreadcrumbProps {
  steps: Step[];
  currentStep?: number;
  className?: string;
}

const Breadcrumb = ({
  steps,
  currentStep = 0,
  className = '',
}: IBreadcrumbProps): React.ReactElement => {
  const { isMobile } = useBreakpoints();

  return (
    <Steps className={className}>
      {steps.map((step: Step, index: number) => {
        if (index < currentStep - 1) {
          return (
            <PastStep key={step.label}>
              <Wrapper>
                <FullCircle>
                  <StyledCheckIcon />
                </FullCircle>
                {!isMobile && <Label>{step.label}</Label>}
              </Wrapper>
            </PastStep>
          );
        }
        if (index === currentStep - 1 && index !== steps.length - 1) {
          return (
            <CurrentStep key={step.label}>
              <Wrapper>
                <Circle>{currentStep}</Circle>
                {index === 0 && <FirstLabel>{step.label}</FirstLabel>}
                {index !== 0 && index !== steps.length - 1 && (
                  <Label>{step.label}</Label>
                )}
              </Wrapper>
            </CurrentStep>
          );
        }

        if (currentStep === steps.length) {
          return (
            <CurrentStep key={step.label}>
              <Wrapper>
                <FullCircle>
                  <StyledCheckIcon />
                </FullCircle>
                <LastLabel>{step.label}</LastLabel>
              </Wrapper>
            </CurrentStep>
          );
        }

        return (
          <FuturStep key={step.label}>
            <Wrapper>
              <SmallCircle />
              {!isMobile && <FuturLabel>{step.label}</FuturLabel>}
            </Wrapper>
          </FuturStep>
        );
      })}
    </Steps>
  );
};

export default Breadcrumb;
