import { ButtonLink, GenderInput, Input } from '@sweb-front/components';
import { ButtonWrapper, StyledButtonLoader } from '@sweb-front/styles';
import {
  ButtonActionWrapper,
  StyledBackButtonWrapper,
  StyledValidateButtonWrapper,
  useAppOptions,
} from '@vat/utils';
import { useTranslation } from 'react-i18next';
import { useAppSelector, selectSteps } from '@sweb-front/store';
import { NbOfChildrenWrapper, SituationFamilialeChoiceWrapper } from './styles';
import useSituationFamilialeForm from './useSituationFamilialeForm';
import { useEffect } from 'react';
import { SITUATIONFAMILIALE } from '@vat/configuration';

export const MAX_NB_OF_CHILDREN = 20;
export const SITUATION_CHOICE_VALUE_MAP = [
  { label: 'Célibataire', value: 'C' },
  { label: 'Marié(e)', value: 'M' },
  { label: 'Divorcé(e)', value: 'D' },
  { label: 'Vie maritale / PACS', value: 'K' },
  { label: 'Veuf(ve)', value: 'V' },
];

const SituationFamilialeForm = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const {
    formik,
    nbOfChildErrorMessageContent,
    isFamilySituationError,
    isNbOfChildrenError,
    onNbOfChildrenBlur,
    isFormValid,
    isActionLoading,
    goBackToStep,
    onNbOfChildrenFocused,
    onSubmit,
    onNbOfChildrenChanged,
  } = useSituationFamilialeForm(opportunity, {
    validateOnBlur: true,
    validateOnChange: true,
  });

  const { setAppOptions } = useAppOptions();
  const steps = useAppSelector(selectSteps);
  useEffect(() => {
    setAppOptions((state) => ({ ...state, isMounted: true }));
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <SituationFamilialeChoiceWrapper
        id="familySituation-wrapper"
        aria-invalid={isFamilySituationError}
      >
        <GenderInput
          id="familySituation"
          label={t('situationFamiliale.familySituation.label')}
          value={formik.values.familySituation}
          error-message={formik.errors.familySituation}
          invalid={isFamilySituationError || null}
          onSelectedElementChange={formik.handleChange}
          hasFocus
        >
          {SITUATION_CHOICE_VALUE_MAP.map(({ label, value }) => (
            <selected-button
              value={value}
              secondary="true"
              selected={
                Number(
                  formik.values.familySituation?.replace(' ', '').trim()
                ) === Number(value)
              }
              key={`family_situation_${value}`}
            >
              {label}
            </selected-button>
          ))}
        </GenderInput>
      </SituationFamilialeChoiceWrapper>
      <NbOfChildrenWrapper
        id="nbOfChildren-wrapper"
        aria-invalid={isNbOfChildrenError}
      >
        <Input
          id="nbOfChildren"
          type="number"
          label={t('situationFamiliale.nbOfChildren.label')}
          placeholder={t('situationFamiliale.nbOfChildren.placeholder')}
          onChange={onNbOfChildrenChanged}
          value={formik.values.nbOfChildren}
          errorMessage={nbOfChildErrorMessageContent}
          onFocus={onNbOfChildrenFocused}
          onBlur={onNbOfChildrenBlur}
          maxLength={2}
          with-round
        />
      </NbOfChildrenWrapper>
      <ButtonActionWrapper>
        <StyledValidateButtonWrapper
          type="submit"
          $isDisabled={!isFormValid}
          id="solva-validate-button"
          className={!isFormValid ? 'isDisabled' : ''}
        >
          {isActionLoading ? (
            <StyledButtonLoader
              isLoading
              isTextInline
              isHideBackground={false}
            />
          ) : (
            t('common.validate')
          )}
        </StyledValidateButtonWrapper>
        {!steps?.[0]?.paths.includes(SITUATIONFAMILIALE) && (
          <StyledBackButtonWrapper>
            <ButtonWrapper className="back-button-wrapper">
              <ButtonLink
                label={t('common.back')}
                onClick={goBackToStep}
                id="back-button"
              />
            </ButtonWrapper>
          </StyledBackButtonWrapper>
        )}
      </ButtonActionWrapper>
    </form>
  );
};

export default SituationFamilialeForm;
