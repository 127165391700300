import { useEffect, useState } from 'react';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import {
  ERRORPAGE,
  LIVENESS,
  REFUSEDREPONSE,
  VENDORLIST,
} from '@vat/configuration';
import { postData, setIdHashed } from 'src/containers/forms/utils';
import { useCloseOpportunity, useManagingExternalApp } from '@vat/utils';
import { useAcceptationPolicy } from '@sweb-front/hooks';
import {
  IOpportunityState,
  IParameterState,
  setEndParcours,
  setLastAppUrl,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
} from '@sweb-front/store';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '@sweb-front/utils';

export type TExtJwtPayloand = JwtPayload & {
  vendorId: string;
};

const useLiveness = (
  token: string,
  personId: string,
  isVATRib: boolean,
  opportunity: IOpportunityState,
  parameters: IParameterState
) => {
  const { vendorId } = jwtDecode(token) as TExtJwtPayloand;
  const [showModal, setShowModal] = useState(false);
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [itemClicked, setItemClicked] = useState<string>();
  const { getAcceptationPolicy } = useAcceptationPolicy();
  const { initOnBo } = useManagingExternalApp();
  const onShowModalFn = () => {
    setShowModal((prec) => !prec);
    // when hiding the modal, we reset the state
    if (showModal) {
      setIsLoading(false);
    } else {
      trackEvent({
        event: 'popinDisplay',
        modaleName: 'E-Commerce : Liveness : Identité : annuler',
      });
    }
  };

  useEffect(() => {
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'pageLoaded',
        site: 'Ecommerce',
        pageName: 'E-Commerce : Liveness : Identité',
        ContributorCode: Number(opportunity.distributor.distributorNb),
        Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails?.tncRt as number) ?? undefined,
        Term: (financialDetails?.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        opportunityIdHashed: setIdHashed(opportunity),
        workflow: parameters.wayCd,
      });
    }
  }, []);

  const onLivenessAuthorized = async () => {
    if (isLoading) {
      return;
    }
    setItemClicked('authorize');
    setIsLoading(true);
    await postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [
          {
            personId,
            livenessConsentBo: true,
          },
        ],
      },
      () => {
        initOnBo(() => {
          dispatch(
            updateParcoursNavigation({
              name: LIVENESS,
              actionPageDone: true,
            })
          );
        });
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: LIVENESS,
            actionPageDone: false,
            params: {
              hasError: true,
            },
          })
        );
        navigate(ERRORPAGE);
      }
    );
  };
  const isOrange = VENDORLIST.ORANGE === vendorId;

  const onRefuseLiveness = async () => {
    if (isLoading) {
      return;
    }
    setItemClicked('not-authorize');
    setIsLoading(true);
    await postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [
          {
            personId,
            livenessConsentBo: false,
          },
        ],
      },
      async () => {
        try {
          await getAcceptationPolicy(
            isVATRib ? 'RIB' : 'CB',
            'SOLVA',
            (response) => {
              if (
                response.data.scoreLightCd === 'R' ||
                !response.data.liveness
              ) {
                dispatch(
                  updateParcoursNavigation({
                    name: LIVENESS,
                    actionPageDone: true,
                  })
                );
                dispatch(setEndParcours(true));
                dispatch(setLastAppUrl(REFUSEDREPONSE));
                navigate(REFUSEDREPONSE);
              }
              setIsLoading(false);
            },
            () => {
              dispatch(
                updateParcoursNavigation({
                  name: LIVENESS,
                  actionPageDone: false,
                  params: {
                    hasError: true,
                  },
                })
              );
              updateAbortErrorMessage(
                `useLiveness::getAcceptationPolicy an error occured while getting the score and the user declined the liveness`
              );
              setIsLoading(false);
              navigate(ERRORPAGE);
            }
          );
        } catch {
          dispatch(
            updateParcoursNavigation({
              name: LIVENESS,
              actionPageDone: false,
              params: {
                hasError: true,
              },
            })
          );

          updateAbortErrorMessage(
            `useLiveness::getAcceptationPolicy an error occured while getting the score and the user declined the liveness`
          );
          setIsLoading(false);
          navigate(ERRORPAGE);
        }
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: LIVENESS,
            actionPageDone: false,
            params: {
              hasError: true,
            },
          })
        );
        updateAbortErrorMessage(
          `useLiveness::updateOpportunity an error occured while updating the opportunity`
        );
        setIsLoading(false);
        navigate(ERRORPAGE);
      }
    );
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    showModal,
    isOrange,
    isLoading,
    itemClicked,
    onLivenessAuthorized,
    onShowModalFn,
    onRefuseLiveness,
  };
};

export default useLiveness;
