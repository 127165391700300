import { ModalWrapper, StyledValidateButtonWrapper } from "@vat/utils";
import { BaseNotificationCard } from "src/libs/components/DesignSystem";
import { styled } from "styled-components";
import { ButtonPositionWrapper } from "src/libs/components/Modal/styles";
import { TAB } from "@sweb-front/utils";

export const CloseButtonWrapper = styled.div`
  height: 4.8rem;
  display: flex;
  justify-content: end;
  margin-bottom: 2rem;
`;

export const ButtonClosePositionWrapper = styled(ButtonPositionWrapper)`
  position: relative;
  right: 0;
  margin-top: 1.2rem;
  margin-right: 1.6rem;
  border: none;
`;

export const ContentWrapper = styled.div`
  font-family: Open Sans;
  padding: 0 2.4rem;
`;

export const ButtonWrapper = styled.div`
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: end;
`;

export const StyledCloseButtonWrapper = styled(StyledValidateButtonWrapper)`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const WarningCard = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  background-color: var(--orange-50);
  padding: 8px 0px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;

  & #title {
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: left;
  }
  & #icon {
    margin-left: 8px;
    min-width: 24px;
    margin-top: 1.4rem;
    --color-icon: var(--warning-500);
    @media screen and ${TAB} {
      min-width: 32px;
    }
  }
`;

export const FaqWrapper = styled.div`
  margin: 2.8rem 0;
`;

export const QuestionWrapper = styled.div`
  font-family: "Open Sans Bold";
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  text-align: left;
`;

export const BoldSpan = styled.span`
  font-family: "Open Sans Bold";
`;
