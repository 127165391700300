import { Section } from '@sweb-front/components';
import styled from 'styled-components';

export const MainSectionWrapper = styled(Section)`
  font-size: 1.2rem;
`;

export const StyledParagraph = styled.div`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1.6rem 0;
`;

export const StyledTitle = styled.h1`
  font-family: 'Ubuntu Bold';
  color: var(--neutrals-700);
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: bold;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;
