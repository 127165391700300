/* eslint-disable consistent-return */
import { useLoadingHook, useManagingStep } from '@sweb-front/hooks';
import {
  useAppDispatch,
  useAppSelector,
  setIncomes,
  setOutgoings,
  IOpportunityState,
  updateParcoursNavigation,
} from '@sweb-front/store';
import { IPerson } from '@sweb-front/types';
import { isStringEmpty, trackEvent } from '@sweb-front/utils';
import { computeStringToNumber, ErrorContext } from '@vat/utils';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { postData, setIdHashed } from '../utils';
import { SOLVALONG } from '@vat/configuration';

const MAX_INCOME = 99999;

type IncomeExpensesLongOptions = {
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
};

const INCOME_MAP = {
  income: 'SAL',
} as Record<string, string>;
const OUTGOING_MAP = {
  rentOrCredit: 'LOY',
  ongoingDebt: 'CCO',
  childSupport: 'ALI',
} as Record<string, string>;

const useIncomesExpensesLongForm = (
  opportunity: IOpportunityState,
  options?: IncomeExpensesLongOptions
) => {
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.parameters.state);
  const { goBackToStep, goToNextStep } = useManagingStep();
  const { validateOnBlur, validateOnChange } = options || {};
  const { t } = useTranslation();
  const { person = {} as IPerson } = opportunity;
  const updateError = useContext(ErrorContext);

  const [isSubmitClicked, setSubmitClicked] = useState(false);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const incomesExpensesLongSchema = z
    .object({
      income: z
        .string({
          required_error: t('incomeAndExpensesLong.income.errors.required'),
          invalid_type_error: t('incomeAndExpensesLong.income.errors.required'),
        })
        .superRefine((value, ctx) => {
          const valueAsNumber = Number(computeStringToNumber(value));
          if (valueAsNumber >= 0 && valueAsNumber <= MAX_INCOME) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['income'],
            message: t('incomeAndExpensesLong.income.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }),
      nbOfMonths: z
        .string({
          required_error: t('incomeAndExpensesLong.nbOfMonths.errors.required'),
          invalid_type_error: t(
            'incomeAndExpensesLong.nbOfMonths.errors.incorrect'
          ),
        })
        .optional()
        .superRefine((value, ctx) => {
          if (
            formik.values.income &&
            Number(computeStringToNumber(formik.values.income)) <= 0
          ) {
            return;
          }
          const valueAsNumber = Number(computeStringToNumber(value || ''));
          if (valueAsNumber <= 16 && valueAsNumber >= 12) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('incomeAndExpensesLong.nbOfMonths.errors.required'),
            path: ['nbOfMonths'],
          });
        }),
      rentOrCredit: z
        .string({
          required_error: t(
            'incomeAndExpensesLong.rentOrCredit.errors.required'
          ),
          invalid_type_error: t(
            'incomeAndExpensesLong.rentOrCredit.errors.required'
          ),
        })
        .superRefine((value, ctx) => {
          const valueAsNumber = Number(computeStringToNumber(value));
          if (valueAsNumber >= 0 && valueAsNumber <= MAX_INCOME) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['rentOrCredit'],
            message: t('incomeAndExpensesLong.rentOrCredit.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }),
      ongoingDebt: z
        .string({
          required_error: t(
            'incomeAndExpensesLong.ongoingDebt.errors.required'
          ),
          invalid_type_error: t(
            'incomeAndExpensesLong.ongoingDebt.errors.required'
          ),
        })
        .superRefine((value, ctx) => {
          const valueAsNumber = Number(computeStringToNumber(value));
          if (valueAsNumber >= 0 && valueAsNumber <= MAX_INCOME) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['ongoingDebt'],
            message: t('incomeAndExpensesLong.ongoingDebt.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }),
      childSupport: z
        .string({
          required_error: t(
            'incomeAndExpensesLong.childSupport.errors.required'
          ),
          invalid_type_error: t(
            'incomeAndExpensesLong.childSupport.errors.required'
          ),
        })
        .superRefine((value, ctx) => {
          const valueAsNumber = Number(computeStringToNumber(value));
          if (valueAsNumber >= 0 && valueAsNumber <= MAX_INCOME) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['childSupport'],
            message: t('incomeAndExpensesLong.childSupport.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }),
    })
    .superRefine((val, ctx) => {
      const incomeAsNumber = Number(computeStringToNumber(val.income));
      if (incomeAsNumber > 0 && val.nbOfMonths === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('incomeAndExpensesLong.nbOfMonths.errors.required'),
          path: ['nbOfMonths'],
        });
      }
    });

  const onFormikValidate = (values: any) => {
    const result = incomesExpensesLongSchema.safeParse(values) as any;
    if (result.success) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };

  const onFormikSubmit = async (
    values: Partial<{
      income: string;
      rentOrCredit: string;
      ongoingDebt: string;
      childSupport: string;
      nbOfMonths?: string | undefined;
      bankAccountOpenedSince?: string | undefined;
    }>
  ) => {
    if (!isFormValid || isActionLoading) {
      formik.setSubmitting(false);
      console.error('Tried submitting invalid form');
      return;
    }
    updateIsActionLoading(true);
    localStorage.setItem(
      'solva-rib',
      JSON.stringify({ income: values.income, nbOfMonths: values.nbOfMonths })
    );
    const incomes = Object.entries(values)
      .map(([key, value]) => {
        if (!isStringEmpty(INCOME_MAP[key])) {
          return {
            incomeAmt: computeStringToNumber(value) || 0,
            incomeTypeCd: INCOME_MAP[key],
            incomeNb:
              computeStringToNumber(value) > 0
                ? Number(values.nbOfMonths)
                : null,
          };
        }
        return undefined;
      })
      .filter((row) => row !== undefined);
    const outgoings = Object.entries(values)
      .map(([key, value]) => {
        if (OUTGOING_MAP[key]) {
          return {
            outgoingAmt: computeStringToNumber(value) || 0,
            outgoingTypeCd: OUTGOING_MAP[key],
          };
        }
        return undefined;
      })
      .filter((row) => row !== undefined);
    dispatch(setIncomes(incomes as never));
    dispatch(setOutgoings(outgoings as never));
    postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [
          {
            personId: person.personId,
            incomes,
            outgoings,
          },
        ],
      },
      async () => {
        updateIsActionLoading(false);
        dispatch(
          updateParcoursNavigation({
            name: SOLVALONG,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: SOLVALONG,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  };

  type IncomesExpensesLongSchema = z.infer<typeof incomesExpensesLongSchema>;

  const { outgoings: storedOutgoings } = person;
  const storedIncome = JSON.parse(localStorage.getItem('solva-rib') || '{}');
  const storedRentOrCredit = storedOutgoings?.find(
    (storedOutgoing) =>
      storedOutgoing.outgoingTypeCd === OUTGOING_MAP.rentOrCredit
  )?.outgoingAmt;
  const storedOngoingDebt = storedOutgoings?.find(
    (storedOutgoing) =>
      storedOutgoing.outgoingTypeCd === OUTGOING_MAP.ongoingDebt
  )?.outgoingAmt;
  const storedChildSupport = storedOutgoings?.find(
    (storedOutgoing) =>
      storedOutgoing.outgoingTypeCd === OUTGOING_MAP.childSupport
  )?.outgoingAmt;
  const formik = useFormik<Partial<IncomesExpensesLongSchema>>({
    initialValues: {
      income:
        storedIncome !== undefined && storedIncome?.income !== undefined
          ? String(storedIncome.income)
          : undefined,
      nbOfMonths:
        storedIncome !== undefined && storedIncome?.nbOfMonths !== undefined
          ? String(storedIncome.nbOfMonths)
          : undefined,
      rentOrCredit:
        storedRentOrCredit !== undefined
          ? String(storedRentOrCredit)
          : undefined,
      ongoingDebt:
        storedOngoingDebt !== undefined ? String(storedOngoingDebt) : undefined,
      childSupport:
        storedChildSupport !== undefined
          ? String(storedChildSupport)
          : undefined,
      // bankAccountOpenedSince: undefined,
    },
    initialTouched: {
      income:
        storedIncome !== undefined && storedIncome?.incomeAmt !== undefined,
      nbOfMonths:
        storedIncome !== undefined && storedIncome?.incomeNb !== undefined,
      rentOrCredit: storedRentOrCredit !== undefined,
      ongoingDebt: storedOngoingDebt !== undefined,
      childSupport: storedChildSupport !== undefined,
      // bankAccountOpenedSince: undefined,
    },
    validateOnMount: storedIncome !== undefined,
    validateOnBlur,
    validateOnChange,
    validate: onFormikValidate,
    onSubmit: onFormikSubmit,
  });
  useEffect(() => {
    /* eslint no-underscore-dangle: 0 */
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire Solva : Revenus',
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity.distributor.distributorNb),
        Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails?.tncRt as number) ?? undefined,
        Term: (financialDetails?.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
      });
    }
  }, []);

  const [isIncomeFocused, setIsIncomeFocused] = useState(false);
  const [isOngoingDebtFocused, setIsOngoingDebtFocused] = useState(false);

  const onIncomeFocus = () => {
    setIsIncomeFocused(true);
    formik.setTouched({ ...formik.touched, income: true }, validateOnBlur);
  };
  const onRentOrCreditFocus = () => {
    formik.setTouched(
      { ...formik.touched, rentOrCredit: true, nbOfMonths: true },
      validateOnBlur
    );
  };
  const onOngoingDebtFocus = () => {
    setIsOngoingDebtFocused(true);
    formik.setTouched({ ...formik.touched, ongoingDebt: true }, validateOnBlur);
  };
  const onChildSupportFocus = () => {
    formik.setTouched(
      { ...formik.touched, childSupport: true },
      validateOnBlur
    );
  };

  const isNumberOfMonthsValid = useMemo(
    () =>
      !!formik.touched.rentOrCredit &&
      formik.errors.nbOfMonths === undefined &&
      String(formik.values.nbOfMonths) !== '',
    [
      formik.touched.rentOrCredit,
      formik.errors.nbOfMonths,
      formik.values.nbOfMonths,
    ]
  );

  const isIncomeError = useMemo(
    () => formik.touched.income && !!formik.errors.income,
    [formik.touched.income, formik.errors.income]
  );

  const isRentOrCreditError = useMemo(
    () => !!formik.errors.rentOrCredit,
    [formik.errors.rentOrCredit]
  );
  const isOngoingDebtError = useMemo(
    () => !!formik.errors.ongoingDebt,
    [formik.errors.ongoingDebt]
  );
  const isChildSupportError = useMemo(
    () => !!formik.errors.childSupport,
    [formik.errors.childSupport]
  );

  const isFormValid = useMemo(() => {
    return Object.entries(formik.values)
      .filter(
        ([key, _]) =>
          !(
            key === 'nbOfMonths' &&
            parseInt(formik.values.income ?? '', 10) === 0
          )
      )
      .every(([_, val]) => val !== undefined && val !== '');
  }, [formik.dirty, formik.errors, formik.values]);

  const onHideBubbleInfoIncome = () => {
    setIsIncomeFocused(false);
  };

  const onHideOutgoingDebt = () => {
    setIsOngoingDebtFocused(false);
  };

  const onSubmitclick = (e: MouseEvent) => {
    if (!isFormValid) {
      e.preventDefault();
      console.error('Tried submitting invalid form');
      return;
    }
    setSubmitClicked(true);
  };

  const onChangeIncome = useCallback(
    (v?: string) => {
      formik.setFieldValue('income', v);
    },
    [formik]
  );

  const onChangeRentOrCredit = useCallback(
    (v?: string) => {
      formik.setFieldValue('rentOrCredit', v);
    },
    [formik]
  );
  const onChangeOngoingDebt = useCallback(
    (v?: string) => {
      formik.setFieldValue('ongoingDebt', v);
    },
    [formik]
  );

  const onChangeChildSupport = useCallback(
    (v?: string) => {
      formik.setFieldValue('childSupport', v);
    },
    [formik]
  );

  const nbOfMonthIsInvalid = useMemo(
    () => (formik.touched.rentOrCredit && !isNumberOfMonthsValid) || null,
    [isNumberOfMonthsValid, formik.touched.rentOrCredit]
  );

  return {
    formik,
    isIncomeFocused,
    isIncomeError,
    isNumberOfMonthsValid,
    isRentOrCreditError,
    isOngoingDebtFocused,
    isOngoingDebtError,
    isChildSupportError,
    isFormValid,
    nbOfMonthIsInvalid,
    isSubmitClicked,
    isActionLoading,
    goBackToStep,
    onSubmitclick,
    onIncomeFocus,
    onHideBubbleInfoIncome,
    onRentOrCreditFocus,
    onOngoingDebtFocus,
    onHideOutgoingDebt,
    onChildSupportFocus,
    onChangeIncome,
    onChangeRentOrCredit,
    onChangeOngoingDebt,
    onChangeChildSupport,
  };
};

export default useIncomesExpensesLongForm;
