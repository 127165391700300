import { Section } from '@sweb-front/components';
import styled from 'styled-components';

export const MainSectionWrapper = styled(Section)`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const BoldSpan = styled.span`
  font-family: 'Open Sans Bold';
`;

export const TextLine = styled.div``;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0;
`;

export const Title = styled.h1`
  font-family: Ubuntu Bold;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.2rem;
`;
