import Styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons';

const linkStyle = css`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  padding: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};

  &:focus {
    border: none;
      outline: none;
    }
  }
`;

export const StyledLink = Styled(Link)`
  ${linkStyle}
`;

export const StyledDisabledLink = Styled(Link)`
  ${linkStyle}
  cursor: not-allowed;
  color: ${({ theme }) => `${theme.variables.colors.chartColors.grey_100}`};
`;

export const StyledButton = Styled.button`
  ${linkStyle}
`;

export const StyledChevronLeftIcon = Styled(ChevronLeftIcon)`
  margin-right: 0.8rem;
  height: 1.1rem;
`;

export const StyledChevronRightIcon = Styled(ChevronRightIcon)`
  margin-left: 0.8rem;
  height: 1.4rem;
`;
