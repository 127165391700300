import styled from 'styled-components';

export const Wrapperlabel = styled.div`
  display: flex;
`;
export const LabelWithStatus = styled.label`
  font-size: 1.5rem;
  width: 100%;
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-weight: normal;
`;
