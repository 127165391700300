import { ERRORKEY } from '@vat/configuration';
import axios, { AxiosInstance } from 'axios';
import mem from 'memoizee';

export default function axiosInstance(): AxiosInstance {
  return mem(
    (): AxiosInstance => {
      localStorage.removeItem(ERRORKEY);
      const axiosBack = axios.create({
        /* eslint no-underscore-dangle: 0 */
        baseURL: (window as unknown as WindowWithEnv)._env_?.msBffBaseURL,
        headers: {
          keyId: (window as unknown as WindowWithEnv)._env_?.apimKey,
        },
        timeout: 30000,
        timeoutErrorMessage: 'ECONNTIMEOUT',
      });

      axiosBack.interceptors.response.use(
        (configRequest) => {
          const withReqId = { ...configRequest };
          return withReqId;
        },
        (error) => {
          if (error.response === undefined) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(400);
          }
          const errCode = error.response.status;
          localStorage.setItem(ERRORKEY, `${errCode}`);
          return Promise.reject(errCode);
        }
      );

      return axiosBack;
    },
    {
      primitive: true,
    }
  )();
}
