/* eslint-disable no-nested-ternary */
import { DESKTOP, TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const InputContainer = styled.div`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  @media screen and ${DESKTOP} {
    max-width: 40rem;
  }
`;

export const StyledInput = styled.input`
  border: none;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  ::placeholder {
    font-family: 'Open Sans';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  &:focus-within {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  width: 100%;
  @media screen and ${TAB} {
    max-width: 20rem;
  }
`;

export const InputAfterText = styled.span`
  width: 6rem;
  user-select: none;
`;

export const CheckMark = styled.span`
  user-select: none;
`;

export const StyledInputContainerWithBorder = styled.div<{
  isFocused: boolean;
  isError: boolean;
  isValid?: boolean;
}>`
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${(props) => {
    if (props.isValid) {
      return '#268038';
    }
    if (props.isFocused) {
      return '#1068D0';
    }
    if (props.isError) {
      return '#EF3654';
    }
    return '#c1c6c8';
  }};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  padding: 1rem 0.75rem;
  width: 100%;
`;

export const StyledErrorMessage = styled.span`
  color: #ef3654;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StyledLabel = styled.label`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledInputWithResetButton = styled.div<{
  width?: string;
}>`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  cursor: text;
  ${(props) => (props.width !== undefined ? `width: ${props.width};` : '')}
`;

export const StyledResetButton = styled.button<{ isSelected?: boolean }>`
  background: ${(props) => (props.isSelected ? '#E8F5E9' : 'none')};
  cursor: pointer;
  border: 1px solid ${(props) => (props.isSelected ? '#268038' : '#c1c6c8')};
  border-radius: 4px;
  min-width: 8.1rem;
  max-width: 11rem;
  height: 50px;
  padding: 1rem 0.75rem;
  color: ${(props) => (props.isSelected ? '#268038' : '#4b4f54')};
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

export const StyledAfterContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;
