import { IOpportunityState, IParameterState } from '@sweb-front/store';
import { IFinancialDetails } from '@sweb-front/types';

import { trackEvent } from '@sweb-front/utils';
import { useContext, useEffect } from 'react';
import { setIdHashed } from '../../containers/forms/utils';
import { PageLoadingContext } from '@vat/utils';

const useRefusedPayment = (
  opportunity: IOpportunityState,
  parameters: IParameterState
) => {
  const { wayType } = parameters;
  const updateIsLoading = useContext(PageLoadingContext);
  useEffect(() => {
    let financialDetails = {} as IFinancialDetails;
    if (opportunity && opportunity.offers?.length > 0) {
      financialDetails = opportunity.offers[0].loans[0].financialDetails;
    }
    if (opportunity.opportunityIdExt && parameters.wayCd) {
      trackEvent({
        event: 'module_interaction',
        pageName: 'E-Commerce : FR',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (financialDetails.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails.tncRt as number) ?? undefined,
        Term: (financialDetails.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity.distributor?.distributorNb ?? ''),
      });
    }
    updateIsLoading(false);
  }, [opportunity.opportunityIdExt, parameters.wayCd]);

  return {
    wayType,
  };
};

export default useRefusedPayment;
