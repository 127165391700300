/* eslint-disable no-underscore-dangle */
import {
  useLoadingHook,
  useManagingStep,
  usePageRedirection,
} from '@sweb-front/hooks';
import {
  setAssurance,
  updateParcoursNavigation,
  useAppDispatch,
} from '@sweb-front/store';

import { IInsurance } from '@sweb-front/types';
import {
  getJSONDataFromStorage,
  isStringEmpty,
  trackEvent,
} from '@sweb-front/utils';
import { INSURANCE, STOREDASSUKEY } from '@vat/configuration';

import { getInsurance } from '@vat/services';
import { ErrorContext, useCloseOpportunity } from '@vat/utils';
import { GenderInputDS, RadioButtonCardDS } from '@sweb-front/components';
import { IOpportunityState } from '@sweb-front/store';

import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IPriceDetails } from 'src/containers/insuranceDetails/items/OptionalInsuranceCard';
import {
  postData,
  scrollToFirstInvalidElement,
  setIdHashed,
} from 'src/containers/forms/utils';

export interface IValues {
  selected: string;
  adhesion?: boolean;
  notadhesion?: boolean;
}

const SANSASSURANCE = 'Sans assurance';
const useOptions = (
  opportunityState: IOpportunityState,
  codeParameters: string,
  formRef?: RefObject<HTMLFormElement>,
  assuRef?: RefObject<GenderInputDS>
) => {
  const dispatch = useAppDispatch();
  const updateError = useContext(ErrorContext);
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const { goBackToStep, goToNextStep } = useManagingStep();
  const [isShown, setIsShown] = useState<boolean>();
  const [detailToShow, setDetailToShow] = useState<string>();
  const [priceDetails, setPriceDetails] = useState<IPriceDetails[]>();
  const [assurances, setAssurances] = useState<IInsurance[]>([]);
  const { t } = useTranslation();
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const [values, setValues] = useState<IValues>({
    selected: '',
  });
  const [showConsentmentError, setShowConsentmentError] = useState(false);
  const storedValues = getJSONDataFromStorage(
    localStorage.getItem('token') ?? '',
    STOREDASSUKEY
  ) as IValues;

  const { isPageLoading } = usePageRedirection(INSURANCE);

  useEffect(() => {
    const { financialDetails } =
      opportunityState?.offers?.[0]?.loans?.[0] ?? {};
    if (opportunityState?.opportunityIdExt) {
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: codeParameters,
        pageName: 'E-Commerce : Formulaire Assurance',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: financialDetails?.overdraftAmt as number,
        Rate: financialDetails?.tncRt as number,
        Term: financialDetails?.term as number,
        MonthlyPayment:
          financialDetails?.monthlyPaymentWithoutInsuranceAmt as number,
        opportunityIdHashed: setIdHashed(opportunityState),
        ContributorCode: Number(opportunityState.distributor.distributorNb),
      });
    }
  }, []);

  useEffect(() => {
    if (isPageLoading) {
      return;
    }
    getInsurance(opportunityState.offers?.[0]?.loans?.[0]?.loanId ?? '', () => {
      dispatch(
        updateParcoursNavigation({
          name: INSURANCE,
          loaded: true,
          actionPageDone: false,
          disabled: false,
          params: {
            hasError: true,
          },
        })
      );
      updateAbortErrorMessage(
        'getInsurance:: le service de récupération des assurances a rencontré un problème'
      );
      updateError();
    }).then((result: any) => {
      const insurances = [
        ...((result?.insurances ?? []) as IInsurance[]),
        { optionCd: SANSASSURANCE },
      ];
      setPriceDetails(
        insurances
          ?.filter((ins) => ins.optionCd !== SANSASSURANCE)
          .map(
            (ins) =>
              ({
                assuKey: ins.optionCd ?? '',
                monthlyCost: ins.insuranceMonthlyPaymentAmt ?? '',
                totalCost: ins.insuranceTotalAmt ?? '',
                yearlyInsuranceAmount: ins.taeaRt ?? '',
              } as IPriceDetails)
          )
      );
      dispatch(setAssurance(insurances ?? []));
      setAssurances(insurances ?? []);
      setValues((prev) => ({
        ...prev,
        selected: storedValues?.selected ?? '',
        adhesion: storedValues?.adhesion,
        notadhesion: storedValues?.notadhesion,
      }));
    });
  }, [isPageLoading]);

  const onOptionChange = useCallback(
    (e) => {
      const evt = e.target as unknown as RadioButtonCardDS;
      if (evt.selected) {
        assuRef?.current?.removeAttribute('invalid');
        setValues((prev) => ({
          ...prev,
          selected: evt.value ?? '',
        }));
      } else {
        const previousSelected = values.selected;
        // trick: because the RadioButtonCard does not have a deselect attribute,
        // reassign the same value will not cause the re-render of the component
        setValues((prev) => ({
          ...prev,
          selected: '',
        }));

        setValues((prev) => {
          return {
            ...prev,
            selected: evt.value,
            adhesion:
              prev.adhesion && evt.value !== SANSASSURANCE
                ? previousSelected === evt.value
                : undefined,
            notadhesion:
              prev.notadhesion && evt.value === SANSASSURANCE
                ? previousSelected === SANSASSURANCE
                : undefined,
          };
        });
      }
    },
    [assuRef, values]
  );

  const onOpenInsuranceDetails = useCallback(
    (value: string) => {
      setDetailToShow(value);
      setIsShown(!isShown);
    },
    [isShown]
  );

  const onSelectOption = useCallback(
    (value: string) => {
      assuRef?.current?.removeAttribute('invalid');
      const previousSelected = values.selected;
      setValues((prev) => ({
        ...prev,
        adhesion:
          prev.adhesion && value !== SANSASSURANCE
            ? previousSelected === value
            : undefined,
        notadhesion:
          prev.notadhesion && value === SANSASSURANCE
            ? previousSelected === SANSASSURANCE
            : undefined,
        selected: value,
      }));
      setIsShown(false);
    },
    [values]
  );

  const onToogleModal = useCallback(() => {
    setIsShown((preVal) => !preVal);
  }, []);

  const onStepBack = useCallback(() => {
    goBackToStep();
  }, []);

  const onChangeConsentement = (e) => {
    const { name, checked } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: checked,
      // uncheck the other radio button
      [name === 'adhesion' ? 'notadhesion' : 'adhesion']: undefined,
    }));
    setShowConsentmentError(false);
  };

  const isValidButtonDisabled = useMemo(() => {
    let res = false;
    if (
      (isStringEmpty(values.selected) || !values.adhesion) &&
      (isStringEmpty(values.selected) || !values.notadhesion)
    ) {
      res = true;
    }
    return res;
  }, [values]);

  const submitClick = useCallback(async () => {
    if (isActionLoading) {
      return;
    }

    if (!isValidButtonDisabled) {
      updateIsActionLoading(true);
      trackEvent({
        event: 'interaction_formulaire',
        FormInteraction: 'clicCTAactif',
        TypeOfInsurance: values.selected?.trim() ?? '',
      });
      await postData(
        'vendors/opportunities/v1/opportunity',
        {
          offers: [
            {
              loans: [
                {
                  insurances: [
                    {
                      optionCd:
                        values.selected === SANSASSURANCE
                          ? '0'
                          : values.selected,
                      categoryCd: 'ASM',
                      insuranceAdviceBo: values.adhesion
                        ? values.adhesion
                        : values.notadhesion,
                    },
                  ],
                  loanId:
                    opportunityState.offers?.[0]?.loans?.[0]?.loanId ?? '',
                },
              ],
              offerId: opportunityState.offers?.[0]?.offerId,
            },
          ],
        },
        async () => {
          localStorage.setItem(
            STOREDASSUKEY,
            JSON.stringify({
              [localStorage.getItem('token') ?? '']: {
                selected: values.selected,
                adhesion: values.adhesion,
                notadhesion: values.notadhesion,
              },
            })
          );
          assuRef?.current?.removeAttribute('invalid');
          updateIsActionLoading(false);
          dispatch(
            updateParcoursNavigation({
              name: INSURANCE,
              actionPageDone: true,
              disabled: false,
            })
          );
          goToNextStep();
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: INSURANCE,
              actionPageDone: false,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateIsActionLoading(false);
          updateError();
        }
      );
    } else if (isStringEmpty(values.selected)) {
      assuRef?.current?.setAttribute('invalid', 'true');
      assuRef?.current?.setAttribute(
        'errorMessage',
        t('incomesAndExpenses.form.errors.option')
      );
      scrollToFirstInvalidElement(formRef);
    } else {
      setShowConsentmentError(true);
    }
  }, [
    values,
    isValidButtonDisabled,
    assuRef,
    formRef,
    opportunityState,
    isActionLoading,
  ]);

  return {
    isShown,
    detailToShow,
    priceDetails,
    assurances,
    isActionLoading,
    values,
    isValidButtonDisabled,
    showConsentmentError,
    isPageLoading,
    onChangeConsentement,
    onOptionChange,
    onOpenInsuranceDetails,
    onSelectOption,
    onToogleModal,
    submitClick,
    onStepBack,
  };
};

export default useOptions;
