import { useUrlWebMarchand } from '@sweb-front/hooks';
import { selectNavigation, useAppSelector } from '@sweb-front/store';
import { ERRORKEY, STOREDVATTYPE } from '@vat/configuration';
import { ERRORDETAILS } from '@sweb-front/services';
import { abortOpportunity } from '@vat/services';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { trackEvent } from '@sweb-front/utils';

const TOKENNAME = 'SolvaData';

const useErrorPage = () => {
  const { t } = useTranslation();
  const { getUrlError } = useUrlWebMarchand();
  const appNavigation = useAppSelector(selectNavigation);

  const onBackToWebMerchant = useCallback(() => {
    const urlError = getUrlError();
    if (urlError) window.location.href = urlError;
  }, [appNavigation.routingContexts]);

  useEffect(() => {
    // The user does not have to now the path of our application
    window.history.replaceState({}, document.title, '/');
  }, []);

  const isEndParcours = useMemo(() => {
    return appNavigation?.endParcours;
  }, [appNavigation?.endParcours]);

  const errorCode = localStorage.getItem(ERRORKEY);
  const isExpired = errorCode === '401';

  const canCallingAbort = useMemo(() => {
    const isActiveStatus =
      errorCode !== null &&
      ['ENCO', 'PAPP'].includes(appNavigation?.opportunityStatus);
    return isActiveStatus || (errorCode !== '401' && isActiveStatus);
  }, [errorCode, appNavigation?.opportunityStatus]);

  if (isExpired) {
    localStorage.removeItem(TOKENNAME);
  }

  const executeAbort = async (
    isParcoursDone: boolean,
    token: string,
    withAbort: boolean,
    errorMessage?: string
  ) => {
    if (!isParcoursDone && withAbort) {
      await abortOpportunity(token, errorMessage ?? '')
        .then(() => {
          console.log(
            `Aborted opportunity successfully, \n\r Details: ${(
              errorMessage ?? ''
            )?.replace(', $OtherReasons', '')}`
          );
          localStorage.removeItem(ERRORDETAILS);
        })
        .catch((err) => console.error('Error while aborting opportunity', err));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedError = localStorage.getItem(ERRORDETAILS) ?? '';

    if (!token) {
      console.error("Couldn't abort opportunity, no token found");
      return;
    }
    executeAbort(isEndParcours, token, canCallingAbort, storedError);
  }, []);

  const regexpVatcb = new RegExp('VATC');
  const isVatCB = regexpVatcb.test(localStorage.getItem(STOREDVATTYPE) ?? '');
  const text = isVatCB ? t('errorPage.bcNotUsed') : '';

  useEffect(() => {
    if (isExpired) {
      trackEvent({
        event: 'popinDisplay',
        modaleName: 'E-Commerce : Session expirée',
      });
    } else  if (errorCode) {
      trackEvent({
        event: 'module_interaction',
        typeOfError: `Erreur ${errorCode}`,
        pageName: 'E-Commerce : Erreur Technique',
      });
    }
  }, [isExpired, errorCode]);

  const getErrorMessage = () => {
    if (isExpired) {
      return {
        title: t('errorPage.title.expired'),
        firstParagraph: t('errorPage.reDoTheLoan'),
        secondParagraph: text,
      };
    }

    return {
      title: t('errorPage.title.message'),
      firstParagraph: t('errorPage.problemEncountered'),
      secondParagraph: t('errorPage.tryLater'),
    };
  };

  return {
    onBackToWebMerchant,
    getErrorMessage,
  };
};

export default useErrorPage;
