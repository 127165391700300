import { IOption, INestedOption } from '@sweb-front/types';
import { LocalStorageService } from '@vat/utils';
import { ERROR_LIST } from '@sweb-front/services';
import axiosInstance from './httpCommon';
import { isStringEmpty } from '@sweb-front/utils';

export const LIST_ACTIVITY_SECTORS = 'activitySectors';
export const LIST_BANKS = 'banks';
export const LIST_CHILDREN = 'children';
export const LIST_HOUSING_STATUS = 'housingStatus';
export const LIST_IDENTITY_PROOF_TYPES = 'identityProofTypes';
export const LIST_IDENTITY_PROOF_TYPES_UE = 'identityProofTypesUE';
export const LIST_MARITAL_STATUS = 'maritalStatus';
export const LIST_MATERIALS = 'materials';
export const LIST_TITLES = 'titles';

const mapList = (data) =>
  data.map((item) => ({
    id: item.id,
    label: item.name,
  }));
const mapNestedList = (data) =>
  data.map((item) => ({
    id: item.id,
    label: item.name,
    nestedList: mapList(item.nestedList),
  }));

const handleFailure = (err, onFailure) => {
  const message = err.response ? err.response.data : ERROR_LIST;
  if (onFailure) {
    onFailure(message);
  }
};

export interface IConfiguration {
  envName: string;
  timeout: number;
  launchSnippetUrl: string;
  alternativeOfferThreshold: number;
}
export const mapActivitySectorList = (data) =>
  data.map((item) => ({
    id: item.id,
    label: item.name,
    cspList: mapList(item.cspList),
    ...(item.contractTypeList && {
      contractTypeList: mapList(item.contractTypeList),
    }),
  }));

export const getList = (
  organizationId: string | null,
  listName: string,
  onFailure?: (message: string) => void
): Promise<IOption[]> => {
  const STORAGE_KEY = `list_${listName}`;
  const data = LocalStorageService.getWithExpiry(STORAGE_KEY);
  if (data) {
    return Promise.resolve(JSON.parse(data));
  }

  return axiosInstance()
    .get(`/organizations/${organizationId}/parameters/lists/${listName}`, {})
    .then((response) => {
      const listTransformed = mapList(response.data);
      LocalStorageService.setWithExpiry(
        STORAGE_KEY,
        JSON.stringify(listTransformed)
      );

      return Promise.resolve(listTransformed);
    })
    .catch((err) => handleFailure(err, onFailure));
};

export const getNestedList = (
  organizationId: string | null,
  listName: string,
  onFailure?: (message: string) => void
): Promise<INestedOption[]> => {
  const STORAGE_KEY = `list_${listName}`;
  const data = LocalStorageService.getWithExpiry(STORAGE_KEY);
  if (data) {
    return Promise.resolve(JSON.parse(data));
  }

  return axiosInstance()
    .get(`/organizations/${organizationId}/parameters/lists/${listName}`, {})
    .then((response) => {
      const listTransformed = mapNestedList(response.data);
      LocalStorageService.setWithExpiry(
        STORAGE_KEY,
        JSON.stringify(listTransformed)
      );

      return Promise.resolve(listTransformed);
    })
    .catch((err) => handleFailure(err, onFailure));
};

export const getProfessionalSituationParameters = async (
  token: string,
  successCallback?: (response) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().get(
      `vendors/v1/referentiel?token=${token}`
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const fetchParametersFromBase = async (
  distributorNb: string,
  overdraftAmt: string,
  productTypeCd: string,
  openBankingChoice?: string,
  openBankingStatus?: string
) => {
  try {
    let url: string =
      'vendors/v1/' +
      distributorNb +
      '/parameters?overdraft=' +
      overdraftAmt +
      '&productType=' +
      productTypeCd;
    if (!isStringEmpty(openBankingChoice))
      url = url.concat('&openBankingChoice=', openBankingChoice);
    if (!isStringEmpty(openBankingStatus))
      url = url.concat('&openBankingStatus=', openBankingStatus);
    const response = await axiosInstance().get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
