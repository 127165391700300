import { IAstrickNote } from '@sweb-front/types';

export const ERROR_LIST = 'List not Valid:';
export const ERROR_TOKEN = 'Token not Valid:';
export const VENDOR_ID = '1009100405'; // Temp to edit once defined by a database
export const opportunityId = 'opportunityIDD';
export const COUNTRY_ID = 'FRA';
export const ASSURANCE_DOC_INFORMATION =
  'IPID-ADE-CONSO-PF-ADE-VAT-DISTRIBUTION-202102.pdf';
export const ASSURANCE_FICHE_CONSEIL = 'FICA-VAT-Distribution-2822-728.pdf';
export const ASSURANCE_NOTICE = 'ADE-VAT-Distribution-2822-728.pdf';
export const CONTEXT_ECONTRAT = 'ECONTRACT';
export const CONTEXT_ECONTRAT_PROVISOIRE = 'ECONTRACTP';
export const CONTEXT_DONE = 'DONE';
export const CONTEXT_CANCEL = 'CANCEL';
export const CONTEXT_KO = 'KO';
export const CONTEXT_LATER = 'LATER';
export const CONTEXT_ERROR = 'ERROR';
export const ERRORDETAILS = 'ERRORDETAILS';

/** *********************************** */
export const ASTRICK_NOTES: IAstrickNote[] = [
  {
    reference: 'D',
    meaning: 'Décès',
  },
  {
    reference: 'I',
    meaning: 'Invalidité (Perte Totale et Irréversible d’Autonomie)',
  },
  {
    reference: 'M',
    meaning: 'Maladie (Incapacité Temporaire Totale de Travail)',
  },
  {
    reference: 'C',
    meaning: 'Chômage (Perte d’emploi suite à un licenciement)',
  },
];
