import { isStringEmpty } from '@sweb-front/utils';
import ErrorDesk from './ErrorDeskImage';

import {
  TitleCmp,
  ContentWrapper,
  ErrorPageWrapper,
  CenterWrapper,
} from './styles';
import useErrorPage from './useErrorPage';

const ErrorPage = () => {
  const { getErrorMessage } = useErrorPage();
  const { title, firstParagraph, secondParagraph } = getErrorMessage();

  return (
    <ErrorPageWrapper data-testid="ErrorPageWrapper">
      <ErrorDesk />
      <CenterWrapper>
        <ContentWrapper>
          <TitleCmp>{title}</TitleCmp>
          <span>{firstParagraph}</span>
          {!isStringEmpty(secondParagraph) && <span>{secondParagraph}</span>}
        </ContentWrapper>
      </CenterWrapper>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
