import styled from 'styled-components';

export const SectorListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0 1.6rem 0;
  & gender-input {
    & selected-button {
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 2.4rem 1.6rem;
      text-align: center;
      width: 47.2%;
      height: 5.6rem;
    }
  }
`;

export const CustomSectorListWrapper = styled.div`
  width: 100%;
  margin: 1.6rem 0 3.2rem 0;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin: 1.6rem 0;
  & gender-input {
    display: flex;
    font-size: 1.6rem;
    & selected-button {
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2.4rem;
      flex-grow: 1;
      padding: 1.5rem 0;
    }
  }
`;
