import { Modal } from '@sweb-front/components';
import { ModalWrapper } from '@vat/utils';
import { TitleWrapper } from 'src/libs/components/ModalSelect/styles';
import {
  BoldSpan,
  ButtonClosePositionWrapper,
  ButtonWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  FaqWrapper,
  QuestionWrapper,
  StyledCloseButtonWrapper,
  WarningCard,
} from './style';
import { useTranslation } from 'react-i18next';
import { ButtonClose, StyledCloseIcon } from 'src/libs/components/Modal/styles';
import { useEffect } from 'react';
import { trackEvent } from '@sweb-front/utils';

interface IModalProps {
  closeModal: () => void;
}

const ModalOpenBankingChoise = (props: IModalProps) => {


  useEffect(() => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Open Banking : données récupérées',
    });
  }, []);


  const { t } = useTranslation();
  return (
    <Modal isVisible={true} isFullScreen={false}>
      <ModalWrapper>
        <CloseButtonWrapper>
          <ButtonClosePositionWrapper>
            <ButtonClose onClick={props.closeModal}>
              <StyledCloseIcon isDisabled={false} />
            </ButtonClose>
          </ButtonClosePositionWrapper>
        </CloseButtonWrapper>
        <ContentWrapper>
          <TitleWrapper>{t('openBanking.modal.title')}</TitleWrapper>
          <div>{t('openBanking.modal.description')}</div>
          <div>
            <ul>
              <li>
                <BoldSpan>BIC/IBAN</BoldSpan>
              </li>
              <li>
                <BoldSpan>Historique bancaire</BoldSpan> de tous vos comptes
                courants sur les 6 derniers mois.
              </li>
            </ul>
          </div>
          <WarningCard>
            <base-icon icon="warning-Fill" id="icon" />
            <div>
              <p id="title"> {t('openBanking.modal.warning-message')}</p>
            </div>
          </WarningCard>

          <FaqWrapper>
            <QuestionWrapper>
              {t('openBanking.modal.faq.identifiersQuestion')}
            </QuestionWrapper>
            {t('openBanking.modal.faq.identifiersAnswer')}
          </FaqWrapper>

          <FaqWrapper>
            <QuestionWrapper>
              {t('openBanking.modal.faq.securityQuestion')}
            </QuestionWrapper>
            {t('openBanking.modal.faq.securityAnswer')}
          </FaqWrapper>
        </ContentWrapper>
        <ButtonWrapper>
          <StyledCloseButtonWrapper onClick={props.closeModal}>
            {t('common.close')}
          </StyledCloseButtonWrapper>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default ModalOpenBankingChoise;
