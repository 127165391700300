import styled from 'styled-components';
import { RadioButtonCard } from '../DesignSystem';

export interface BadgeProps {
  isRecommended?: boolean;
}

export const CardStyle = {
  margin: '1rem 0 0 0',
};

export const RadioButtonWrapper = styled(RadioButtonCard)`
    --title-background-color: var(--information-500);
    --title-color: #ffffff;
    --content-padding: 3.2rem 0;
    margin-right:1.6rem;
    margin-top:1.6rem;
    width:100%;

    & span[slot='title'] {
      font-family: 'Ubuntu Bold';
      font-size: 1.8rem;
      line-height: 2.6rem;
    }

    & div[slot='acronym'] {
      display: inline;
      font-family: 'Ubuntu Bold';
      font-size: 1.8rem;
      line-height: 2.6rem;

      & span {
        display:block;
        font-family: 'Open Sans';
        font-size: 1.4rem;
        line-height: 2rem;
        margin: .4rem 0;
      }
    }
    
    & a[slot='link'] {
      font-family: 'Open Sans';
      line-height: 2.4rem;
      font-size: 1.6rem;
      color: var(--main-green-500);
      margin-bottom: .8rem;
    }
  }
`;
export const Title = styled.div`
  margin: 0.25rem 0;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModalDocument};
  font-family: Ubuntu Bold;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const SubTitle = styled.span`
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin-left: ${({ theme }) => theme.variables.layout.spaces.small};
  font-family: Ubuntu;
  font-weight: lighter;
  color: var(--neutrals-500);
`;

export const PriceBadge = styled.span<{ isRecommended?: boolean }>`
  border-radius: 1rem;
  background-color: ${(props) => (props.isRecommended ? '#e5f5fd' : '#eff8f0')};
  margin: 0.5rem 0;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  font-weight: bold;
  font-size: 1.4rem;
`;

export const DetailsLink = styled.a`
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
  margin: 5px 0;
  text-decoration: underline;
`;

export const Description = styled.span`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
`;
