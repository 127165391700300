import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  border-radius: 0.4rem;
`;
export const BannerTitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0rem 0 1.6rem 0;
`;

export const EditWrapper = styled.div`
  margin-top: 0.4rem;
  & a {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0;
    width: 7.6rem;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0;
  font-weight: bold;

  & span {
    font-family: 'Ubuntu Bold';
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
    word-break: break-word;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  padding-bottom: 0;
`;

export const CardItemWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--neutrals-200);
  padding: 0 0 0.4rem 0;

  &:not(:first-of-type) {
    margin-top: 0.8rem;
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const CardStyle = {
  margin: '1rem 0 0 0',
};
