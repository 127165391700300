import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IDistritutor,
  IOffer,
  IPerson,
  IPersonalInformation,
} from '@sweb-front/types';
import {
  IFamily,
  IHousing,
  IAddress,
  IEmail,
  IPhone,
  IIncomeInput,
  IOutgoingInput,
} from '@sweb-front/types';
import { IState as IOpportunityState } from './opportunityTypes';
import { IProfessionInput } from 'src/libs/types/PersonnalInformations';

const getInitialState = () => {
  return { state: {} } as { state: IOpportunityState };
};

export const opportunitySlice = createSlice({
  name: 'opportunitySlice',
  initialState: getInitialState(),
  reducers: {
    clear(opportunity) {
      opportunity.state = getInitialState().state;
    },
    fetchOpportunity(opportunity, data: PayloadAction<IOpportunityState>) {
      opportunity.state = data.payload;
    },
    setPerson(opportunity, data: PayloadAction<IPerson>) {
      opportunity.state.person = data.payload;
    },
    setFamilySituation(opportunity, data: PayloadAction<IFamily>) {
      opportunity.state.person.familySituation = data.payload;
    },
    setHousing(opportunity, data: PayloadAction<IHousing>) {
      opportunity.state.person.housing = data.payload;
    },
    setOffers(opportunity, data: PayloadAction<IOffer[]>) {
      opportunity.state.offers = data.payload;
    },
    setIncomes(opportunity, data: PayloadAction<IIncomeInput[]>) {
      opportunity.state.person.incomes = data.payload;
    },
    setOutgoings(opportunity, data: PayloadAction<IOutgoingInput[]>) {
      opportunity.state.person.outgoings = data.payload;
    },
    setOpportunityId(opportunity, data: PayloadAction<string>) {
      opportunity.state.opportunityIdExt = data.payload;
    },
    setAddress(opportunity, data: PayloadAction<Partial<IAddress>>) {
      opportunity.state.person.address = data.payload;
    },
    setEmail(opportunity, data: PayloadAction<Partial<IEmail>>) {
      opportunity.state.person.email = data.payload;
    },
    setPhone(opportunity, data: PayloadAction<Partial<IPhone>>) {
      opportunity.state.person.cellPhone = data.payload;
    },
    setPersonalInformation(
      opportunity,
      data: PayloadAction<IPersonalInformation>
    ) {
      opportunity.state.person.personalInformation = {
        ...opportunity.state.person.personalInformation,
        ...data.payload,
      };
    },
    setDistributor(parametersSlice, data: PayloadAction<IDistritutor>) {
      parametersSlice.state.distributor = { ...data.payload };
    },
    setProfession(opportunity, data: PayloadAction<IProfessionInput>) {
      opportunity.state.person.profession = {
        ...opportunity.state.person.profession,
        ...data.payload,
      };
    },
  },
});

export type { IOpportunityState };

export const {
  clear,
  fetchOpportunity,
  setPerson,
  setFamilySituation,
  setHousing,
  setIncomes,
  setOutgoings,
  setDistributor,
  setAddress,
  setEmail,
  setPhone,
  setPersonalInformation,
  setOffers,
  setProfession,
} = opportunitySlice.actions;

export default opportunitySlice.reducer;
