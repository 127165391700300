import { useState, createRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BaseButton } from '../DesignSystem';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Wrapper,
  WrapperButton,
  IconWrapper,
  StyledTitle,
  StyledText,
  TitleWithIconContainer,
  TitleWithIconWrapper,
  IconWithTitleWrapper,
} from './styles';

export type VariantType = 'column' | 'inline';

export interface PromptProps {
  icon?: React.ReactNode;
  title: string | React.ReactNode;
  variant?: VariantType;
  text: string | React.ReactNode;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  isPrimaryButtonDisabled?: boolean;
  tertiaryButtonLabel?: string;
  onTertiaryButtonClick?: () => void;
  isSpecificStyle?: boolean;
  withScroll?: boolean;
}

const Prompt = ({
  icon,
  title,
  variant = 'column',
  text,
  secondaryButtonLabel,
  primaryButtonLabel,
  tertiaryButtonLabel,
  onSecondaryButtonClick,
  onPrimaryButtonClick,
  onTertiaryButtonClick,
  isSecondaryButtonDisabled,
  isPrimaryButtonDisabled,
  isSpecificStyle,
  withScroll = true,
}: PromptProps): React.ReactElement => {
  const PerfectScrollbarRef = createRef<PerfectScrollbar>();
  const [isScrollBottom, setScrollBottom] = useState(false);
  const [isScrollTop, setScrollTop] = useState(false);

  const onScroll = (e) => {
    setScrollBottom(
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    );
    setScrollTop(e.target.scrollTop);
  };

  let titleWithIcon: React.ReactNode = null;

  switch (variant) {
    case 'column': {
      titleWithIcon = (
        <>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {title && <StyledTitle>{title}</StyledTitle>}
        </>
      );
      break;
    }
    case 'inline': {
      titleWithIcon = (
        <>
          <TitleWithIconContainer>
            {icon && <IconWithTitleWrapper>{icon}</IconWithTitleWrapper>}
            {title && <TitleWithIconWrapper>{title}</TitleWithIconWrapper>}
          </TitleWithIconContainer>
        </>
      );
      break;
    }
    default:
      <></>;
  }

  return (
    <Wrapper
      isSpecificStyle={isSpecificStyle}
      isBottom={isScrollBottom}
      isTop={isScrollTop}
      withScroll={withScroll}
    >
      {titleWithIcon}
      <PerfectScrollbar
        role="list"
        onScroll={onScroll}
        ref={PerfectScrollbarRef}
        options={{
          minScrollbarLength: 20,
          maxScrollbarLength: 60,
          wheelSpeed: 0.5,
        }}
      >
        {text && (
          <StyledText isSpecificStyle={isSpecificStyle}>{text}</StyledText>
        )}

        <WrapperButton>
          {secondaryButtonLabel && (
            <BaseButton
              onClick={onSecondaryButtonClick}
              disabled={isSecondaryButtonDisabled}
            >
              {secondaryButtonLabel}
            </BaseButton>
          )}
          {primaryButtonLabel && (
            <BaseButton
              onClick={onPrimaryButtonClick}
              disabled={isPrimaryButtonDisabled}
            >
              {primaryButtonLabel}
            </BaseButton>
          )}
          {tertiaryButtonLabel && (
            <BaseButton onClick={onTertiaryButtonClick}>
              {tertiaryButtonLabel}
            </BaseButton>
          )}
        </WrapperButton>
      </PerfectScrollbar>
    </Wrapper>
  );
};

export default Prompt;
