import { IThemeDemat } from '@sweb-front/types';
import colors from './colors';
import layout from './layout';
import fonts from './fonts';
import * as themes from './themes';

const variables = {
  colors,
  layout,
  fonts,
};

const cetelemTheme = {
  variables,
  vendor: themes.cetelem,
};

export const getTheme = () => {
    return cetelemTheme;
};

export const getThemeDemat = (): IThemeDemat => {
  const theme = getTheme();
  return {
    leftLogo: `${window.location.origin}${window.location.pathname}logos/${theme.vendor.logo}.png`,
    ...theme.vendor.colorsDemat,
  };
};
