import { useTranslation } from 'react-i18next';
import { Section } from '@sweb-front/components';
import useObConnexionError from './useObConnexionError';
import { useAppSelector } from '@sweb-front/store';
import {
  ObButtonAWrapper,
  ObContentWrapper,
  ObTitleWrapper,
  StyledContinueButtonWrapper,
} from './style';
import { StyledButtonLoader } from '@sweb-front/styles';
import { trackEvent } from '@sweb-front/utils';
import { useEffect } from 'react';
import { usePageRedirection } from '@sweb-front/hooks';
import { OBCONNEXIONERROR } from '@vat/configuration';

const ObConnexionError = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { onSubmit, isActionLoading } = useObConnexionError(opportunity);
  const { isPageLoading } = usePageRedirection(OBCONNEXIONERROR, {
    locked: true,
  });

  useEffect(() => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Open Banking : Connexion perdue',
    });
  }, []);

  return (
    !isPageLoading && (
      <>
        <Section title="" description="">
          <ObTitleWrapper>
            {t('openBanking.obConnexionError.title')}
          </ObTitleWrapper>

          <ObContentWrapper>
            {t('openBanking.obConnexionError.description')}
          </ObContentWrapper>
          <ObButtonAWrapper>
            <StyledContinueButtonWrapper onClick={onSubmit}>
              {isActionLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                t('openBanking.obConnexionError.button')
              )}
            </StyledContinueButtonWrapper>
          </ObButtonAWrapper>
        </Section>
      </>
    )
  );
};

export default ObConnexionError;
