import { Section, Title } from '@sweb-front/components';
import { NextButtonWrapper } from '@sweb-front/styles';
import styled from 'styled-components';

export const SectionWrapper = styled(Section)`
  position: relative;
  padding: 0;
`;

export const SVGIconWrapper = styled.div`
  display: inline-flex;
  margin: 0;
  margin-top: 1.2rem;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
  & base-icon {
    width: 4rem;
    height: 4rem;
    --icon-width: 4rem;
    --icon-height: 4rem;
    --color-icon: var(--neutrals-700);
  }
`;

export const ContentWrapper = styled.div`
  margin: 0.8rem 0 1.6rem 0;
  text-align: center;
`;

export const TitleCmp = styled(Title)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 2.8rem;
  color: var(--neutrals-700);
`;

export const Paragraph = styled.p`
  text-align: left;
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-top: 1.2rem;
`;

export const ContentParagraph = styled(Paragraph)`
  margin-top: 1.2rem;
`;

export const BoldParagraph = styled(ContentParagraph)`
  font-family: 'Open Sans Bold';
`;

export const CustomizedValidateButtonWrapper = styled(NextButtonWrapper)`
  display: flex;
  margin-top: 4rem;
  & base-button {
    flex-grow: 1;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-family: 'Open Sans Bold';
    --color-button: var(--main-green-500);
    background: var(--main-green-500);
    border: 1px solid var(--main-green-500);

    & base-icon {
      width: 3.2rem;
      --color-icon: var(--neutrals-0);
      margin-right: 0.3rem;
      margin-top: 0.2rem;
    }
  }
`;

export const BackButtonWrapper = styled(CustomizedValidateButtonWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  & base-button,
  base-button:hover {
    color: var(--neutrals-0);
    --color-button: var(--neutrals-0);
    background: var(--main-green-500);
    max-width: 32rem;
    height: 5.6rem;
  }
`;
