export const CheckMarkIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>88D77F1E-38C1-4EA8-9E3D-82305600414D</title>
      <g
        id="✅-Formulaire"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="[desktop]-4---Formulaire"
          transform="translate(-498.000000, -330.000000)"
          fill="#268038"
        >
          <g
            id="Base-select/Modal/Validated"
            transform="translate(280.000000, 284.000000)"
          >
            <g id="Group" transform="translate(0.000000, 32.000000)">
              <g
                id="Ic/24px/Action/check2"
                transform="translate(218.000000, 14.000000)"
              >
                <path
                  d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M14.6439394,6.26780122 C14.3509096,5.97504473 13.8760359,5.97526637 13.5832794,6.26829625 L8.99979155,11.8034315 L6.75536921,9.55915746 C6.46246632,9.26627391 5.98759258,9.2662896 5.69470904,9.55919249 C5.40182549,9.85209538 5.40184118,10.3269691 5.69474407,10.6198527 L8.46974407,13.3946693 C8.76273679,13.6876427 9.23778791,13.687524 9.53063418,13.3944043 L14.6444345,7.32846128 C14.937191,7.03543139 14.9369693,6.56055771 14.6439394,6.26780122 Z"
                  id="check-circle-fill"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckMarkIcon;
