import {
  useManagingStep,
  useBreakpoints,
  useLoadingHook,
} from '@sweb-front/hooks';
import {
  getJSONDataFromStorage,
  isStringEmpty,
  trackEvent,
} from '@sweb-front/utils';
import { SITUATIONPROF, STOREDPRFKEY } from '@vat/configuration';
import { ErrorContext } from '@vat/utils';
import { DateInputDS } from '@sweb-front/components';
import {
  IOpportunityState,
  updateParcoursNavigation,
  useAppDispatch,
} from '@sweb-front/store';
import { useState, useEffect, useCallback, RefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getProfessionalSituationParameters } from '@vat/services';

import { IListItem } from '@sweb-front/components';
import { useAppSelector } from '@sweb-front/store';
import {
  arrayRange,
  isInputValidToPattern,
  pad,
  postData,
  scrollToFirstInvalidElement,
  setIdHashed,
} from '../utils';

export interface IValues {
  activitySector: string;
  activity: string;
  contractType?: string;
  entryDt?: string;
}

export const getInvalidityState = (
  field: string,
  value: string | null | undefined,
  invalidityFieldsStates: any,
  ptn: string
) => {
  if (invalidityFieldsStates[field] === null) {
    return null;
  }

  if (['entryDt'].includes(field)) {
    return isStringEmpty(value) || !isInputValidToPattern(value, ptn);
  }

  return isStringEmpty(value);
};

const maxYear = new Date().getFullYear();
const maxMonth = new Date().getMonth() + 1;

const allowedMonthList = arrayRange(1, maxMonth, 1).map((m) => pad(m));
const allMonthsList = arrayRange(1, 12, 1).map((m) => pad(m));

// Check on by one the validity of the field
export const isFieldInvalid = (
  field: string,
  value: string | undefined,
  ptn?: string
) => {
  if (['entryDt'].includes(field)) {
    return isStringEmpty(value) || !isInputValidToPattern(value, ptn ?? '');
  }

  return isStringEmpty(value);
};

// Check form validity within field invalidities
export const checkFormValidity = (fields: IValues, ptn: string) => {
  const fieldToCheck = {
    ...fields,
  } as Record<string, string>;
  return !Object.keys(fieldToCheck).some((field) =>
    isFieldInvalid(field, fieldToCheck[field], ptn)
  );
};

export const onSetInvalidityFieldState = (
  fieldValues: IValues,
  ptn: string
) => {
  return {
    activitySector: isFieldInvalid(
      'activitySector',
      fieldValues.activitySector
    ),
    activity: isFieldInvalid('activity', fieldValues.activity),
    contractType: isFieldInvalid('contractType', fieldValues.contractType),
    entryDt: isFieldInvalid('entryDt', fieldValues.entryDt, ptn),
  };
};

export const computeContractYear = (date: string, ptn: string) => {
  const regExp = new RegExp(ptn);
  if (regExp.test(date)) {
    const [month, year] = date.split('/');
    const newDt = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1
    ).getTime();
    return (Date.now() - newDt) / (3600 * 24 * 365 * 1000);
  }
  return -1;
};

export const computeContracIntMonth = (nb: number) => {
  return nb > -1 ? Math.ceil(nb * 12) : 0;
};

export interface IList {
  id: string;
  name: string;
}

export interface ISector extends IList {
  seniorityMax: number;
  seniorityMin: number;
  cspList: IList[];
  contractTypeList: IList[];
}

export const getListByDevice = (
  isDesktop: boolean,
  list: ISector[] | IList[]
) => {
  return isDesktop
    ? (list ?? []).map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : (list ?? []).map((item) => ({
        value: item.id,
        displayValue: item.name,
      }));
};

const useProfessionalForm = (
  opportunity: IOpportunityState,
  formRef?: RefObject<HTMLFormElement>,
  contractDtRef?: RefObject<DateInputDS>
) => {
  const { isDesktop } = useBreakpoints();
  const { goBackToStep, goToNextStep } = useManagingStep();
  const [nbClickOnSubmit, setNbClickOnSubmit] = useState(0);
  const [isFormValid, setIsFormValid] = useState<boolean>();
  const [computedConcractDuration, setComputedContractDuration] =
    useState<number>();
  const { person } = opportunity;
  const { t } = useTranslation();
  const [isUnMount, setIsUnMount] = useState(false);
  const updateError = useContext(ErrorContext);

  const [sectors, setSectors] = useState<ISector[]>([]);
  const [activities, setActivities] = useState<IList[]>([]);
  const [contractTypes, setContractTypes] = useState<IList[]>([]);
  const [sectorControl, setSectorControl] = useState<ISector[]>([]);
  const [hideContractType, setHideContractType] = useState(false);
  const dateOfBirth = opportunity?.person?.personalInformation.birthDt;
  const parameters = useAppSelector((state) => state.parameters.state);
  const dispatch = useAppDispatch();

  const [birthYear] = (dateOfBirth ?? '').split('-');
  const computedMinYear =
    parseInt(birthYear, 10) > 0 ? parseInt(birthYear, 10) + 16 : 1900;
  const allowedYearList = arrayRange(computedMinYear, maxYear - 1, 1);
  const dtPattern = `^((${allMonthsList.join('|')})/(${allowedYearList.join(
    '|'
  )}))|((${allowedMonthList.join('|')})/(${maxYear}))$`;
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
    trackEvent({
      event: 'module_interaction',
      site: 'Ecommerce',
      workflow: parameters.wayCd,
      pageName: 'E-Commerce : Formulaire Solva : Situation professionnelle',
      // eslint-disable-next-line no-underscore-dangle
      environment: (window as unknown as WindowWithEnv)._env_?.env,
      visitorStatus: 'non-logged',
      opportunityIdHashed: setIdHashed(opportunity),
      ContributorCode: Number(opportunity.distributor.distributorNb),
      Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
      Rate: (financialDetails?.tncRt as number) ?? undefined,
      Term: (financialDetails?.term as number) ?? undefined,
      MonthlyPayment:
        (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
        undefined,
    });
    getProfessionalSituationParameters(
      token,
      ({ activitySectors, activitySectorsControls }) => {
        setSectors(activitySectors ?? []);
        setSectorControl(activitySectorsControls ?? []);
      },
      () => updateError()
    );
  }, []);

  const getContractDtErrorMessage = useCallback((contractDt: string) => {
    if (isStringEmpty(contractDt.replace('/', ''))) {
      return 'professionalSituation.form.errors.entryDt.required';
    }
    return 'professionalSituation.form.errors.entryDt.validDate';
  }, []);

  useEffect(() => {
    setIsUnMount(false);
    return () => {
      setIsUnMount(true);
    };
  }, []);

  const storedValues = getJSONDataFromStorage(
    localStorage.getItem('token') ?? '',
    STOREDPRFKEY
  ) as IValues;

  const [values, setValues] = useState<IValues>({
    activitySector: storedValues?.activitySector ?? '',
    activity: storedValues?.activity ?? '',
    contractType: storedValues?.contractType ?? '',
    entryDt: storedValues?.entryDt ?? '',
  });

  // Manage form field validation
  const [invalidityFieldsStates, setInvalidityFieldStates] = useState<
    Record<string, boolean | null>
  >({
    activitySector: null,
    activity: null,
    contractType: null,
    entryDt: null,
  });

  // Calcule une fois le type de contrat selon la date de contrat enregistrée
  useEffect(() => {
    setComputedContractDuration(
      computeContractYear(storedValues.entryDt ?? '', dtPattern)
    );
  }, []);

  // Valide ou non le bouton valider selon les status des champs
  useEffect(() => {
    const valuesToCheck = { ...values };
    if (hideContractType) {
      delete valuesToCheck.contractType;
    }
    setIsFormValid(checkFormValidity(valuesToCheck, dtPattern));
    contractDtRef?.current?.removeAttribute('error-message');
    return () => {};
  }, [t, values, contractDtRef, hideContractType]);

  // Calcule les types de contrat à afficher
  useEffect(() => {
    const sector = sectors.find((sec) => sec.id === values.activitySector);
    setActivities(sector?.cspList ?? []);
    if (
      !isStringEmpty(values.activitySector) &&
      !isStringEmpty(values.activity)
    ) {
      const concernedSectors = sectorControl?.filter(
        (sct) =>
          sct.id === values.activitySector &&
          (sct?.cspList ?? []).filter((csp) => csp.id === values.activity)
            .length > 0
      );

      const seniority = computeContracIntMonth(computedConcractDuration ?? -1);
      const concernedSectorsWithDate = concernedSectors.filter(
        (cst) => cst.seniorityMin <= seniority && cst.seniorityMax >= seniority
      );
      const concernedContractTypes = concernedSectorsWithDate.map(
        (c) => c.contractTypeList
      );
      const computedContractTypes = Array.from(
        new Set(...concernedContractTypes)
      );
      setContractTypes(computedContractTypes);
      setHideContractType(computedContractTypes.length <= 0);
    } else {
      setHideContractType(false);
    }
  }, [
    sectors,
    values.activitySector,
    values.activity,
    computedConcractDuration,
    sectorControl,
  ]);

  // Sélectionne directement le seul contrat présent
  useEffect(() => {
    if (contractTypes?.length === 1) {
      setValues((prev) => ({
        ...prev,
        contractType: contractTypes[0].id,
      }));
      setInvalidityFieldStates((prev) => ({
        ...prev,
        contractType: null,
      }));
    }
  }, [contractTypes]);

  useEffect(() => {
    if (formRef?.current) {
      scrollToFirstInvalidElement(formRef);
    }
  }, [formRef?.current, nbClickOnSubmit]);

  const onSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isUnMount || isActionLoading) {
        return;
      }
      setNbClickOnSubmit((prev) => prev + 1);
      setInvalidityFieldStates(onSetInvalidityFieldState(values, dtPattern));

      if (isFormValid) {
        if (!isStringEmpty(localStorage.getItem('token'))) {
          localStorage.setItem(
            STOREDPRFKEY,
            JSON.stringify({
              [localStorage.getItem('token') ?? '']: {
                activitySector: values.activitySector,
                activity: values.activity,
                contractType: hideContractType
                  ? undefined
                  : values.contractType ?? undefined,
                entryDt: values.entryDt ?? '',
              },
            })
          );
        }
        const dtList = (`02/${values.entryDt}` ?? '').split('/');
        updateIsActionLoading(true);
        postData(
          'vendors/opportunities/v1/opportunity',
          {
            persons: [
              {
                personId: person.personId,

                profession: {
                  activitySectorCd: values.activitySector,
                  csp: values.activity,
                  contractTypeCd: hideContractType
                    ? undefined
                    : values.contractType ?? undefined,
                  employmentDt: `${dtList[2]}-${dtList[1]}-${dtList[0]}`,
                },
              },
            ],
          },
          () => {
            dispatch(
              updateParcoursNavigation({
                name: SITUATIONPROF,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            updateIsActionLoading(false);
            goToNextStep();
          },
          () => {
            dispatch(
              updateParcoursNavigation({
                name: SITUATIONPROF,
                loaded: true,
                actionPageDone: true,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateIsActionLoading(false);
            updateError();
          }
        );
      }
    },
    [
      values,
      isFormValid,
      person,
      opportunity,
      isUnMount,
      hideContractType,
      isActionLoading,
    ]
  );

  const onActivitySectorChange = useCallback(
    (value: string) => {
      if (value === values.activitySector) {
        return;
      }
      setActivities([]);
      setContractTypes([]);
      setValues((prev) => ({
        ...prev,
        activitySector: value,
        activity: '',
        contractType: '',
      }));
      setInvalidityFieldStates((prev) => ({
        ...prev,
        activitySector: isFieldInvalid('activitySector', value),
        activity: null,
        contractType: null,
      }));
    },
    [values, contractTypes, invalidityFieldsStates]
  );

  const onActivityChange = useCallback(
    (value: string) => {
      if (value === values.activity) {
        return;
      }
      setContractTypes([]);
      setValues((prev) => ({
        ...prev,
        activity: value,
      }));
      setInvalidityFieldStates((prev) => ({
        ...prev,
        activity: isFieldInvalid('activity', value),
        contractType: null,
      }));
    },
    [values, contractTypes]
  );

  const onEntryDtChange = useCallback(
    (e: Event) => {
      const { valueMonth, valueYear } = (e as CustomEvent).target as any;
      const val = `${valueMonth}/${valueYear}`;

      if (val === values?.entryDt) {
        return;
      }

      setValues((prev) => ({
        ...prev,
        entryDt: val,
      }));

      const isEntryDtInvalid = isFieldInvalid('entryDt', val, dtPattern);
      setInvalidityFieldStates((prev) => ({
        ...prev,
        entryDt: isEntryDtInvalid,
      }));

      if (!isEntryDtInvalid) {
        setComputedContractDuration(computeContractYear(val, dtPattern));
      }
    },
    [contractDtRef, values, contractTypes, invalidityFieldsStates]
  );

  const onContractTypeChange = (value: string) => {
    if (values.contractType === value) {
      return;
    }
    setValues((prev) => ({
      ...prev,
      contractType: value,
    }));
    setInvalidityFieldStates((prev) => ({
      ...prev,
      contractType: isFieldInvalid('contractType', value),
    }));
  };

  const onFieldChange = useCallback(
    (e: any, item?: IListItem) => {
      const field = e.target.id?.replace('ms_modal_input-', '').trim();
      if (field === 'contractType') {
        onContractTypeChange(item?.value as string);
      } else if (field === 'activitySector') {
        onActivitySectorChange(item?.value as string);
      } else if (field === 'activity') {
        onActivityChange(item?.value as string);
      }
    },
    [values, contractDtRef, invalidityFieldsStates, contractTypes]
  );

  return {
    values,
    isFormValid,
    isActionLoading,
    invalidityFieldsStates,
    nbClickOnSubmit,
    dtPattern,
    computedConcractDuration,
    activities,
    sectors,
    contractTypes,
    hideContractType,
    isDesktop,
    setInvalidityFieldStates,
    setIsFormValid,
    setValues,
    onSubmit,
    goBackToStep,
    getInvalidityState,
    getContractDtErrorMessage,
    onActivitySectorChange,
    onActivityChange,
    onContractTypeChange,
    onEntryDtChange,
    scrollToFirstInvalidElement,
    onFieldChange,
  };
};

export default useProfessionalForm;
